<template>
  <div class="blog animated fadeIn">
    <div class="body">
      <div class="row">
          <div class="col-md-6 col-6">
             <SidebarView title="Blog" single="true" />
          </div>
          <div class="col-md-6 col-6 text-right">
            <router-link to="/">
            <img src="../assets/images/icon.jpg" class="myicon" />
           </router-link>
        </div>
      </div>
      <br /><br />
      <div class="row" v-if="posts.length == 0">
          <div class="col-md-4 col-6" v-for="loader in postLoader" :key="loader">
              <div class="post-date-loader"></div>
              <div class="post-link-loader"></div>
              <div class="post-link-loader last"></div>
          </div>
      </div>
      <div class="row" v-else>
          <div class="col-md-4 col-6" v-for="post in posts" :key="post.id">
              <span class="post-date">{{ formatDate(post.date) }}</span>
              <a :href="post.link" target="_blank" class="post-link">
                <h5 v-html="post.title.rendered"></h5>
              </a>
          </div>
      </div>
      <br />
      <div class="row">
          <div class="col-md-12 text-center">
            <a href="https://adorahack.com" target="_blank" class="btn btn-read-more">
                Read more articles
            </a>
          </div>
      </div>
    </div>
    <FooterView />
  </div>
</template>

<script>
import SidebarView from '@/components/SidebarView.vue' 
import FooterView from '@/components/FooterView.vue' 

export default {
    components: { 
        SidebarView,
        FooterView 
    },
    data() {
      return {
        posts: [],
        postLoader: [1, 2, 3, 4, 5, 6] 
      }
    },
    methods: {
        formatDate: function(iso){
            let date = new Date(iso)
            return date.toDateString()
        },
        getPosts: function(){
            var posts = JSON.parse(localStorage.getItem("adoranwodo_blogposts"));
            
            if(posts && posts.length > 0){
                this.posts = posts;
            }

            fetch('https://adorahack.com/wp-json/wp/v2/posts')
                .then( 
                    response => { //success
                        return response.json();
                    }, 
                    response => { //error
                        return response.json();
                    }
                )
                .then(posts => { 
                    if(Array.isArray(posts)){
                        this.posts = posts.slice(0, 6);
                        localStorage.setItem("adoranwodo_blogposts", JSON.stringify(this.posts));
                    }else{
                        this.posts = JSON.parse(localStorage.getItem("adoranwodo_blogposts"));
                    }   
                });
        }
    },
    mounted(){
      this.getPosts();    
    },
}
</script>

<style scoped>
.post-date{
    color: #c12688;
}
.blog h5{
    margin-bottom: 50px;
    color: black;
}
.blog h5:hover{
    color: #c12688;
}
.blog a.post-link{
    text-decoration: none !important;
}
.btn-read-more{
    color: black;
    border: 2px solid black;
    border-radius: 0px;
    font-weight: 400 !important;
    margin-bottom: 40px;
}
.post-date-loader{
    height: 13px;
    width: 65%;
    border-radius: 10px;
    background: #ebebeb;
}
.post-link-loader{
    height: 20px;
    width: 100%;
    border-radius: 10px;
    margin-top: 15px;
    background: #ebebeb;
}
.post-link-loader.last{
    margin-bottom: 40px;
}
</style>