<template>
  <div class="book animated fadeIn">
    <div class="body">
      <div class="row">
          <div class="col-md-6 col-6">
             <SidebarView title="Cloud Engineering for Beginners" previous="Books and Courses" previousLink="/books-and-courses" />
          </div>
          <div class="col-md-6 col-6 text-right">
            <router-link to="/">
            <img src="../assets/images/icon.jpg" class="myicon" />
           </router-link>
        </div>
      </div>
      <br /><br />
      <div class="row">
            <div class="col-md-1 col-sm-2"></div>
            <div class="col-md-10 col-sm-8">
                <div class="row">
                    <div class="col-md-5 col-sm-12">
                        <img src="../assets/images/mockup1.png" style="width: 100%;" />    
                    </div>
                    <div class="col-md-1 col-sm-12"></div>
                    <div class="col-md-6 col-sm-12">
                        <h1 class="book-title">
                            CLOUD<br />ENGINEERING<br />
                            <span>FOR<br />BEGINNERS</span>
                        </h1>
                        <br />
                        <p class="orange">By Adora Nwodo</p>
                    </div>
                </div>
                <br /><br /><br />
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <h4>Available Now On:</h4>
                    </div>
                    <div class="col-sm-12 text-center">
                        <img src="../assets/images/selar.png" class="platform-logo" />
                        <img src="../assets/images/gumroad.jpeg" class="platform-logo" />
                        <img src="../assets/images/kindle.png" class="platform-logo" />
                        <img src="../assets/images/amazon.png" class="platform-logo" />
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <a class="btn preorder" href="https://adorahack.selar.co/cloud-engineering-book" target="_blank">Buy on Selar</a>
                        <a class="btn preorder" href="https://adorahack.gumroad.com/l/cloud-engineering-book" target="_blank">Buy on Gumroad</a>
                        <a class="btn preorder" href="https://www.amazon.com/dp/B09HLBMT69" target="_blank">Buy on Kindle</a>
                        <a class="btn preorder" href="https://www.amazon.com/dp/9789966628" target="_blank">Buy on Amazon</a>
                    </div>
                </div>
                <br /><br />
                <div class="row">
                    <div class="col-sm-2"></div>
                    <div class="col-sm-8">
                        <h2 class="quote text-center">"Cloud is about how you do computing, not where you do computing" ~ Paul Maritz</h2>
                    </div>
                    <div class="col-sm-2"></div>
                </div>
                <br /><br />
                <div class="row">
                    <div class="col-sm-1"></div>
                    <div class="col-sm-10">
                        <p>
                            "The Cloud" is a lot more than someone else's computer. It is the foundation for a lot of today's innovative technology.
                            This innovation is creating more opportunities in cloud computing. As a result, there are more cloud engineering roles 
                            coming up in different areas of technology today. The best time to become a cloud engineer, is now! 

                            <br /><br />
                            The concept of the cloud can be abstract for people who have no experience in cloud technologies. When I started my 
                            journey as an Engineer building cloud services for Mixed Reality, a lot of things didn't make sense to me. I would 
                            spend a lot of my time trying to understand why we needed to do Container Orchestration, Zero downtime deployments, 
                            Infrastructure Automation and more. 

                            <br /><br />
                            Prior to starting this role in 2019, I had always thought the important thing was to write code and put it on a server.
                            Getting introduced to cloud computing made me realize that there was a lot more to technology than I was previously 
                            exposed to. As a result, I chose to write a book when I understood these concepts to help cloud beginners like me 
                            learn more about the cloud.
                        </p>
                        <br /><br />
                        <h3 class="orange text-center break">
                            ARE YOU A BEGINNER IN TECH OR A TECHIE IN A DIFFERENT FIELD LOOKING TO SWITCH TO CLOUD ENGINEERING? THIS BOOK IS FOR YOU.
                        </h3>
                        <br /><br />  
                        <p>
                            This is a foundational book for people that want a career in cloud engineering.  To understand why some of these cloud 
                            engineering roles exist, we need to understand the concept of the cloud. The goal of this book is to introduce you to 
                            cloud computing and then, the different career paths for cloud engineering. Once you are able to learn the concepts 
                            introduced in this book, you will be ready to take on more complicated cloud related technologies.
                        </p>
                        <br />
                    </div>
                    <div class="col-sm-1"></div>
                </div>
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <br />
                        <p><em>
                            This book is currently available on <a href="https://adorahack.selar.co/cloud-engineering-book" class="orange custom-link" target="_blank">Selar.co</a>, 
                            <a href="https://adorahack.gumroad.com/l/cloud-engineering-book" class="orange custom-link" target="_blank">Gumroad.com</a> and Amazon (<a href="https://www.amazon.com/dp/9789966628" class="orange custom-link" target="_blank">Hard copy</a> and 
                            <a href="https://www.amazon.com/dp/B09HLBMT69" class="orange custom-link" target="_blank">Ebook</a>), 
                            so you can read from the platform you're most comfortable with!</em>
                        </p>
                        <a class="btn preorder-black" href="https://adorahack.selar.co/cloud-engineering-book" target="_blank">Buy on Selar</a>
                        <a class="btn preorder-black" href="https://adorahack.gumroad.com/l/cloud-engineering-book" target="_blank">Buy on Gumroad</a>
                        <a class="btn preorder-black" href="https://www.amazon.com/dp/B09HLBMT69" target="_blank">Buy on Kindle</a>
                        <a class="btn preorder-black" href="https://www.amazon.com/dp/9789966628" target="_blank">Buy on Amazon</a>
                    </div>
                </div>
                <br /><br />
                <hr />
                <div class="row">
                    <div class="col-md-12">
                        <br /><br />
                        <h3 class="orange text-center">CONTRIBUTORS</h3>
                        <br /><br />
                    </div>
                    <div class="col-md-4 contributor-container text-center">
                        <img src="../assets/images/angie-jones.jpg" class="full-width contributor" />
                        <h5><a href="https://twitter.com/techgirl1908" target="_blank">Angie Jones</a></h5>
                        <p>Senior Director of Developer Relations, Applitools</p>
                    </div>
                    <div class="col-md-4 contributor-container text-center">
                        <img src="../assets/images/hoop-somuah.jpeg" class="full-width contributor" />
                        <h5><a href="https://twitter.com/hoopsomuah" target="_blank">Hoop Somuah</a></h5>
                        <p>Partner Software Engineering Manager, Microsoft Mesh</p>
                    </div>
                    <div class="col-md-4 contributor-container text-center">
                        <img src="../assets/images/scott-hanselman.jpg" class="full-width contributor" />
                        <h5><a href="https://twitter.com/shanselman" target="_blank">Scott Hanselman</a></h5>
                        <p>Partner Program Manager, Microsoft</p>
                    </div>
                </div>
                <br />
                <hr />
                <div class="row">
                    <div class="col-md-12">
                        <br /><br />
                        <h3 class="orange text-center">WHAT YOU'LL LEARN</h3>
                        <br />
                    </div>
                    <div class="col-md-4">
                        <h5>History</h5>
                        <ul>
                            <li>Evolution of computers</li>
                            <li>The Internet</li>
                            <li>The World Wide Web</li>
                            <li><em class="orange">...and more</em></li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <h5>The Cloud</h5>
                        <ul>
                            <li>Cloud Native Applications</li>
                            <li>Measuring Cloud Availability</li>
                            <li>Cloud Security</li>
                            <li>DevOps</li>
                            <li><em class="orange">...and more</em></li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <h5>Career</h5>
                        <ul>
                            <li>Cloud Engineering Careers</li>
                            <li>Acing your Cloud Engineer Interview</li>
                            <li>Long Term Career Growth Tips</li>
                            <li><em class="orange">...and more</em></li>
                        </ul>
                    </div>
                </div>
                <br />
                <hr />
                <br /><br />
                <div class="row">
                    <div class="col-sm-2"></div>
                    <div class="col-sm-8 text-center">
                        <h4 class="orange">GET THIS BOOK NOW</h4>
                        <br /><br />
                        <div class="row">
                            <div class="col-sm-6">
                                <img src="../assets/images/mockup-woman.jpg" class="full-width book-mockup"/>
                            </div>
                            <div class="col-sm-6">
                                <img src="../assets/images/mockup-man.jpg" class="full-width book-mockup"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2"></div>
                </div>
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <a class="btn preorder" href="https://adorahack.selar.co/cloud-engineering-book" target="_blank">Buy on Selar</a>
                        <a class="btn preorder" href="https://adorahack.gumroad.com/l/cloud-engineering-book" target="_blank">Buy on Gumroad</a>
                        <a class="btn preorder" href="https://www.amazon.com/dp/B09HLBMT69" target="_blank">Buy on Kindle</a>
                        <a class="btn preorder" href="https://www.amazon.com/dp/9789966628" target="_blank">Buy on Amazon</a>
                    </div>
                </div>
                <br /><br />
                <hr />
                <div class="row">
                    <div class="col-md-12">
                        <br /><br />
                        <h3 class="orange text-center">ABOUT THE AUTHOR</h3>
                        <br /><br />
                    </div>
                    <div class="col-md-3 author-container">
                        <img src="../assets/images/nenne-min.jpg" class="full-width" />
                    </div>
                    <div class="col-md-9">
                          <p>
                              <router-link to="/" class="txt-black custom-link">Adora Nwodo</router-link> is a Senior Software Engineer currently working
                              at the intersection of Cloud Engineering and Developer Platforms. She is also the Founder of NexaScale, a non-profit helping people
                              start and scale their tech careers, and she is a Vice President of the Virtual Reality/Augmented Reality (VRAR)
                              Association – Nigeria chapter.
                              <br /><br />
                              With a passion for enabling women in technology, Adora publishes content to share her tech journey and teach
                              software engineering on her blog called AdoraHack.
                          </p>
                      </div>
                </div>
            </div>
            <div class="col-md-1 col-sm-2"></div>
      </div>
      <br /><br /><br />
    </div>
    <FooterView />
  </div>
</template>

<script>
import SidebarView from '@/components/SidebarView.vue' 
import FooterView from '@/components/FooterView.vue' 

export default {
    components: { 
        SidebarView,
        FooterView 
    }
}
</script>

<style scoped>
.book{
    width: 100%;
}
a.preorder.btn, a.preorder-black.btn{
    font-family: 'Poppins', sans-serif; font-weight: 300 !important;
    font-size: 17px !important;
  }
.full-width{
    width: 100%;
}
.platform-logo{
    height: 30px !important;
    margin: 10px 20px !important;
}
.book-mockup{
    margin-bottom: 20px;
}
.book .orange{
    color: #FF914D;
}
.book .contributor{
    border-top-left-radius: 40px;
    border-bottom-right-radius: 40px;
    border: 4px dotted #FF914D;
    padding: 10px;
}
.book h5 a{
    font-weight: bolder !important;
    color: black !important;
}
.book ul li{
    margin-left: -20px;
    padding-bottom: 5px;
}
.contributor-container h5{
    margin-top: 15px !important;
}
.contributor-container p{
    margin-bottom: 25px !important;
}
.orange-bg{
    background: #FF914D !important;
}
.txt-black{
    color: black !important;
}
h3.break{
    font-size: 22px !important;
}
a.preorder{
    background: #FF914D;
    font-weight: 400 !important;
    padding: 10px 20px;
    border-radius: 20px;
    color: white;
    font-size: 18px !important;
    border: 2px solid #FF914D;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
}
a.preorder:hover{
    box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
    -webkit-box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
    -moz-box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
    background: white;
    color: #FF914D;
}
a.preorder-black{
    background: #000;
    font-weight: 400 !important;
    padding: 10px 20px;
    border-radius: 20px;
    color: white;
    font-size: 18px !important;
    border: 2px solid #000;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
}
a.preorder-black:hover{
    box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
    -webkit-box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
    -moz-box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
    background: white;
    color: #000;
}
.book .author-container img{
    padding-bottom: 30px;
}
.book h1.book-title span{
    font-weight: 900 !important;
}
.book h2.quote{
    font-family: 'Open Sans', sans-serif;
    color: #ddd;
    font-style: italic;
}
.book small{
    font-size: 11px;
}
.book p{
    font-size: 18px !important;
}
.book p.bolder{
    font-weight: 400 !important;
    color: black;
}
@media only screen and (min-width: 1200px) {
  .book h1.book-title{font-size: 45px !important; padding-top: 50px;}
}
@media only screen and (max-width: 1199px) {
  .book h1.book-title{font-size: 35px !important; padding-top: 30px;}
}
@media only screen and (max-width: 900px) {
  .book h1.book-title{font-size: 28px !important; padding-top: 35px;}
}
@media only screen and (max-width: 869px) {
  p{font-size: 11px !important;}
  .book h2.quote{font-size: 24px !important;}
}
@media only screen and (max-width: 767px) {
  .book h1.book-title{font-size: 50px !important; padding-top: 35px; text-align: center;}
  p{font-size: 20px !important; text-align: center;}
  p a.custom-link, .book h5 a{ font-size: 18px !important; }
}
@media only screen and (max-width: 599px) {
  .book h1.book-title{font-size: 50px !important; padding-top: 40px; text-align: center;}
  p a.custom-link, .book h5 a{ font-size: 18px !important; }
}
@media only screen and (max-width: 480px) {
  .book h1.book-title{font-size: 50px !important; padding-top: 30px; text-align: center;}
   p{font-size: 15px !important; text-align: center;}
   p a.custom-link, .book h5 a{ font-size: 18px !important; }
}
@media only screen and (max-width: 390px) {
  .book h1.book-title{font-size: 40px !important; padding-top: 30px; text-align: center;}
}
</style>
