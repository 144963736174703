<template>
  <div class="home animated fadeIn">
    <div class="row">
      <div class="col-sm-6 col-md-6">
        <div class="body">
          <SidebarView v-show="loaded" />
          <br />
          <img src="../assets/images/home-sm-min.jpg" class="d-block d-sm-none img-full-width" alt="adora" />
          <h1>ADORA</h1>
          <p>Hi, my name is Nenne Adaora Nwodo - but call me Adora :)</p>
          <p>I am a Senior Software Engineer, Multi-Published Author, Digital Creator and the Founder of <a rel="noopener" target="_blank" href="https://nexascale.org">NexaScale</a>.</p>
          <p>Need pictures or my bio for a feature or speaker graphics? <a rel="noopener" target="_blank" href="https://drive.google.com/drive/folders/1mezxNIsHzixsCdHY1dHDLYH3i0akbDj5">click here</a>.</p>
          <p>
            Get my <a rel="noopener" target="_blank" href="https://adorahack.selar.co/cloud-engineering-book">Cloud Engineering for Beginners</a>, 
            <a rel="noopener" target="_blank" href="https://www.wiley.com/en-us/Beginning+Azure+DevOps%3A+Planning%2C+Building%2C+Testing+and+Releasing+Software+Applications+on+Azure-p-9781394165889">Beginning Azure DevOps</a> or 
            <a rel="noopener" target="_blank" href="https://www.koganpage.com/risk-compliance/confident-cloud-9781398615670">Confident Cloud</a> book today!
          </p>
          <ul class="icons-list">
            <li>
              <a rel="noopener" target="_blank" href="https://adorahack.com"><img src="../assets/images/adorahackblack.png" class="adorahack-icon"/></a>
            </li>
            <li>
              <a rel="noopener" target="_blank" href="https://twitter.com/adoranwodo"><i class="fab fa-twitter"></i></a>
            </li>
            <li>
              <a rel="noopener" target="_blank" href="https://instagram.com/adorahack"><i class="fab fa-instagram"></i></a>
            </li>
            <li>
              <a rel="noopener" target="_blank" href="https://www.youtube.com/@adora-nwodo"><i class="fab fa-youtube"></i></a>
            </li>
            <li>
              <a rel="noopener" target="_blank" href="https://medium.com/@adoranwodo"><i class="fab fa-medium-m"></i></a>
            </li>
            <li>
              <a rel="noopener" target="_blank" href="https://github.com/adoranwodo"><i class="fab fa-github"></i></a>
            </li>
            <li>
              <a rel="noopener" target="_blank" href="https://www.linkedin.com/in/adoranwodo/"><i class="fab fa-linkedin-in"></i></a>
            </li>
            <li>
              <a rel="noopener" target="_blank" href="https://en.wikipedia.org/wiki/Adora_Nwodo"><i class="fab fa-wikipedia-w"></i></a>
            </li>
            <li>
              <a rel="noopener" target="_blank" href="https://adorahack.hashnode.dev/"><img src="../assets/images/hashnode.png" class="hashnode-icon"/></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 background-home-image d-none d-sm-block"></div>
    </div>
    <FooterView class="d-block d-sm-none"/>
  </div>
</template>

<script>
import SidebarView from '@/components/SidebarView.vue'
import FooterView from '@/components/FooterView.vue' 

export default {
  data() {
      return {
        loaded: false
      }
  },
  methods:{
  },
  mounted(){
      this.loaded = true;  
  },
  components: {
    SidebarView,
    FooterView
  }
}
</script>

<style scoped>
/* NEW STYLES */
.background-home-image{
  height: 100vh;
  background: url('../assets/images/home-min.jpg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
}
.home .body{
  padding: 50px;
}
.home .body h1{
  padding-top: 90px;
  padding-bottom: 50px;
}
.adorahack-icon{
  width: 17px;
  margin-bottom: 4px;
}
.hashnode-icon{
  width: 17px;
  margin-bottom: -2px; 
}
.icons-list{
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: -40px;
}
.home .icons-list i{
  font-size: 20px;
}
.icons-list li{
  list-style-type: none;
  display: inline;
  margin-right: 10px;
}
.icons-list li img{
  margin-top: -5px;
}
.icons-list li a{
  font-size: 22px;
  color: black !important;
}
.home p a{
  color: black !important;
  text-decoration: underline;
}
.img-full-width{
  width: 100%;
}
@media (max-width: 575px) { 
  .home .body h1{
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .home .icons-list{
    margin-top: 20px;
    margin-bottom: 0px;
  }
 }
</style>

