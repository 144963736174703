<template>
    <div class="portfolio animated fadeIn">
        <div class="body">
            <div class="row">
                <div class="col-md-6 col-6">
                    <SidebarView title="Portfolio" single="true" />
                </div>
                <div class="col-md-6 col-6 text-right">
                    <router-link to="/">
                        <img src="../assets/images/icon.jpg" class="myicon" />
                    </router-link>
                </div>
            </div>
            <br />
            <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <span class="project-title">GOT Trivia</span>
                    </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <p>
                                    The goal of this project was to showcase the benefits of Kotlin by 
                                    building a basic trivia android app. I chose Game of Thrones as a 
                                    domain because it's one of my favorite series.
                                </p><br />
                                <p>
                                    The solution uses Anko SQLite to house the trivia questions, 
                                    Shared Preferences to keep track of high scores & user levels, 
                                    and a Countdown Timer for each game session.
                                </p>
                                <br />
                                <a rel="noopener" target="_blank" href="https://github.com/AdoraNwodo/got-trivia">See more on GitHub</a>
                                <br /><br />
                            </div>
                            <div class="col-md-4 col-sm-6">
                                <img src="../assets/images/portfolio/got/screens.png" />
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span class="project-title">MyFortShop</span>
                    </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div class="row">
                                <div class="col-sm-6">
                                    <p>
                                        Fort NG is a fashion company based in Nigeria. 
                                        Customers place orders by viewing items on instagram and sending messages. 
                                        They wanted an E-Commerce website for users to purchase items from as well as 
                                        a website where potential customers would get the latest news.
                                    </p><br />
                                    <p>The E-Commerce website was built from scratch using Laravel. 
                                        An admin portal was also built to enable admins manage news, 
                                        products, orders as well as website content.</p>
                                    <br /><br />
                                </div>
                                <div class="col-sm-4">
                                    <img src="../assets/images/portfolio/fort/screen1.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        <span class="project-title">Med Manager</span>
                    </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div class="row">
                                <div class="col-sm-6">
                                    <p>
                                        The task was to build a native Android app that reminds patients 
                                        when it is time to take their medications.
                                    </p><br />
                                    <p>
                                        The app has the following features. 
                                        Users sign up using Google Social Authentication; 
                                        Users can update their profile and add medications; 
                                        Users can receive reminders when it is time to take the medication; 
                                        The app categorizes medication intake by month; 
                                        Users can search for medication by name
                                    </p>
                                    <br />
                                    <a rel="noopener" target="_blank" href="https://github.com/AdoraNwodo/MedManager">See more on GitHub</a><br /><br />
                                </div>
                                <div class="col-sm-5">
                                    <img src="../assets/images/portfolio/medmanager/screens.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        <span class="project-title">EverythingShop</span>
                    </button>
                    </h2>
                    <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div class="row">
                                <div class="col-sm-6">
                                    <p>
                                        This is a demo online makeup shop.
                                    </p><br />
                                    <p> The requirements are listed below: <br />
                                        - User should be able to register, login and create orders consisting of multiple products. <br />
                                        - The application would send an email and SMS notification to the user for each order they create with details of the items selected.
                                    </p><br />
                                    <p>This demo was built for built with Vue.js & Node.js. The Twilio API was used to send SMS while SparkPost was used for email notifications</p>
                                    <br /><br />
                                </div>
                                <div class="col-sm-4">
                                    <img src="../assets/images/portfolio/everythingshop/screen2.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingFive">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        <span class="project-title">Krypt</span>
                    </button>
                    </h2>
                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div class="row">
                                <div class="col-sm-6">
                                    <p>
                                        Team Project. <br />
                                        Smartphone users store messages, videos, photos and other multimedia. 
                                        The absence of inbuilt encryption for videos has led to inconveniences 
                                        for users who may choose to protect confidential videos that are just 
                                        saved plainly in their gallery. The videos are open to people who steal 
                                        or somehow have access to their phones.
                                    </p><br />
                                    <p>
                                        Krypt is a native Android app. The system information is housed using an Object Oriented Database (Realm). 
                                        The AES algorithm was used for encryption.
                                    </p>
                                    <br />
                                    <a rel="noopener" target="_blank" href="https://github.com/AdoraNwodo/Krypt">See more on GitHub</a>
                                </div>
                                <div class="col-sm-5">
                                    <img src="../assets/images/portfolio/krypt/screens.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingSix">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        <span class="project-title">3rdeye</span>
                    </button>
                    </h2>
                    <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <div class="row">
                                <div class="col-sm-6">
                                    <p>
                                        3rd eye is a copy-paste plagiarism detection application built 
                                        using the Aho-Corasick pattern matching algorithm. 
                                    </p><br />
                                    <p>
                                        The Aho-Corasick algorithm is a pattern-matching algorithm invented by Alfred V.Aho & Margaret J. Corasick. 
                                        It constructs a finite-state machine (similar to a Trie) for the set of strings. 
                                        It can actually also be used to implement autocomplete for search engines (and more).
                                    </p><br />
                                    <p>
                                        The result was an implementation of the Aho-Corasick algorithm in a plagiarism detection web application. 
                                        The application is able to detect whether a thesis in the reference has been copied. 
                                        A repository exists for students' theses which are collected as source documents for testing if a suspected thesis is plagiarized.
                                    </p>
                                    <br /><br />
                                </div>
                                <div class="col-sm-4">
                                    <img src="../assets/images/portfolio/3rdeye/screen1.jpg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterView />
    </div>
</template>

<script>
import SidebarView from '@/components/SidebarView.vue' 
import FooterView from '@/components/FooterView.vue' 

export default {
    components: { 
        SidebarView,
        FooterView
    }
}
</script>

<style scoped>
/* NEW STYLES */
@media only screen and (max-width: 770px) {
    .portfolio .project-title{
        font-size: 15px !important;
    }
}
.portfolio .card{
    border-color: transparent !important;
}
.portfolio .btn-info{ 
    background: white !important;
    border-color: white !important;
    color: black;
    box-shadow: none !important;
    outline: none !important;
}
.portfolio .card-header{
    background: white !important;
}
.portfolio .portfolio-body{
    padding: 10px 20px;
}
.portfolio .portfolio-body a{
    color: black;
    text-decoration: underline;
}
.portfolio .card img{
    width: 100%;
}
.portfolio .card p{
    margin-bottom: 0px;
}
.portfolio .project-title{
    font-size: 20px;
}
.portfolio .portfolio-body p, .portfolio .portfolio-body a{
    font-size: 13px;
}
.portfolio .portfolio-body a:hover{
    color: #c12688;
}
.portfolio .work-list{
    margin-bottom: 40px;
}
.portfolio .accordion{
    border-radius: 0px !important;
}
.portfolio .accordion-item{
    border: none !important;
    border-bottom: 1px solid rgb(215, 215, 215) !important;
}
.portfolio .accordion-item a{
    color: black;
    font-size: 14px;
}
.portfolio .accordion img{
    width: 100% !important;
}
.portfolio .accordion-button{
    border-color: transparent;
}
.portfolio .accordion-button:focus{
    box-shadow: none;
}
.accordion-button:not(.collapsed){
    box-shadow: none;
    background: white;
    color: black;
}
</style>

