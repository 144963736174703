<template>
  <div class="error animated fadeIn">
    <div class="body">
      <div class="row">
          <div class="col-md-6 col-6">
             <SidebarView title="Page Not Found" single="true" />
          </div>
          <div class="col-md-6 col-6 text-right">
            <router-link to="/">
            <img src="../assets/images/icon.jpg" class="myicon" />
           </router-link>
        </div>
      </div>
        <div class="row error-container">
            <div class="col-sm-12">
                <h1 class="text-center">404</h1>
                <p class="text-center">Page Not Found.</p>

                <p class="text-center">
                    <router-link to="/">Go To Home</router-link>
                </p>
            </div>
        </div>
        <br /><br />
    </div>
    <FooterView />
  </div>
</template>

<script>
import SidebarView from '@/components/SidebarView.vue' 
import FooterView from '@/components/FooterView.vue' 

export default {
    components: { 
        SidebarView,
        FooterView
    },
}
</script>

<style scoped>
.error{min-height: calc(100vh - 120px) !important;}
.error h1{font-family: 'Raleway', sans-serif; font-weight: 900; font-size: 120px !important;}
.error .error-container{padding-top: 140px; padding-bottom: 50px;}
.error p{font-size: 19px;}
.error p a{color: palevioletred !important;}
</style>

