<template>
  <div class="videos animated fadeIn">
    <div class="body">
      <div class="row">
          <div class="col-md-6 col-6">
             <SidebarView title="Videos" single="true" />
          </div>
          <div class="col-md-6 col-6 text-right">
            <router-link to="/">
            <img src="../assets/images/icon.jpg" class="myicon" />
           </router-link>
        </div>
      </div>
      <br /><br />
      <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-6 video-body">
            <div class='embed-container'>
                <iframe 
                    src='https://www.youtube.com/embed/ytgc2IKRpuQ' 
                    frameborder="0" 
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen>
                </iframe>
            </div>
            <img src="../assets/images/io.png" class="pattern" />
          </div>
          <div class="col-md-3"></div>
          <div class="col-md-12 text-center watch-more">
            <a href="https://www.youtube.com/@adora-nwodo" target="_blank" >
                <img src="../assets/images/youtube_icon.png" class="youtube-icon"/>
            </a>
            <a href="https://www.youtube.com/@adora-nwodo" target="_blank" >
                <p>WATCH MORE VIDEOS AT</p>
            </a>
            <a href="https://www.youtube.com/@adora-nwodo" target="_blank" >
                <h2>MY YOUTUBE CHANNEL</h2>
            </a>
          </div>
      </div>
    </div>
    <FooterView />
  </div>
</template>

<script>
import SidebarView from '@/components/SidebarView.vue' 
import FooterView from '@/components/FooterView.vue' 

export default {
    components: { 
        SidebarView,
        FooterView
    }
}
</script>

<style scoped>
.videos{
    background: url("../assets/images/fadedpattern.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
}
.embed-container { 
    position: relative; 
    padding-bottom: 56.25%; 
    height: 0; 
    overflow: hidden; 
    max-width: 100%; 
} 
.embed-container iframe, .embed-container object, .embed-container embed { 
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
}
.video-body{
    background:  rgba(59,158,156, .2);
    padding-top: 15px;
    padding-bottom: 15px;
}
.videos .pattern{
    height: 23px;
    margin-top: 15px;
    float: right;
    margin-right: -15px;
}
.youtube-icon{
    width: 70px;
    margin-top: 30px;
}
.videos .watch-more p{
    margin-bottom: 0px;
    color: black;
}
.videos .watch-more h2{
    font-size: 18px;
    font-weight: 900 !important;
    color: black;
}
.videos .watch-more a:hover p, .videos .watch-more a:hover h2{
    color: #c12688;
    text-decoration: none;
}
.videos .watch-more a{
    text-decoration: none;
}

</style>
