<template>
  <div class="award animated fadeIn">
    <div class="body">
      <div class="row">
          <div class="col-md-6 col-6">
             <SidebarView title="Awards" single="true" />
          </div>
          <div class="col-md-6 col-6 text-right">
            <router-link to="/">
            <img src="../assets/images/icon.jpg" class="myicon" />
           </router-link>
        </div>
      </div>
      <br /><br />
      <div class="row">
            <div class="col-md-4 col-sm-6 award-body">
                <a href="#" target="_blank" class="award-item">
                    <div>
                        <span>Top Software Engineer of the Year</span>
                        <p class="bolder">
                            IAOTP Annual Awards New York, USA <br /><br />
                            <small>
                                15<sup>TH</sup> MARCH 2024
                            </small>
                        </p>
                        <img src="../assets/images/iaotp-min.jpg" class="award-thumbnail"/>
                    </div>
                </a>
            </div>
            <div class="col-md-4 col-sm-6 award-body">
                <a href="https://gulftoday.ae/business/2024/01/25/impact-leadership-global-awards-2024-held-in-dubai" target="_blank" class="award-item">
                    <div>
                        <span>Change Maker Of The Year (Tech) - Winner</span>
                        <p class="bolder">
                            Impact Leadership Global Awards, Dubai <br /><br />
                            <small>
                                20<sup>TH</sup> JANUARY 2024
                            </small>
                        </p>
                        <img src="../assets/images/impact.png" class="award-thumbnail"/>
                    </div>
                </a>
            </div>
            <div class="col-md-4 col-sm-6 award-body">
                <a href="https://www.womentech.net/nominee/Adora/Nwodo/92962?_se=bmVubmVud29kb0BnbWFpbC5jb20%3D" target="_blank" class="award-item">
                    <div>
                        <span>Women Tech Global Awards - Winner</span>
                        <p class="bolder">
                            The 2023 Software Engineering Leader Of The Year Award<br />
                            <small>
                                1<sup>ST</sup> DECEMBER 2023
                            </small>
                        </p>
                        <img src="../assets/images/leader-min.png" class="award-thumbnail"/>
                    </div>
                </a>
            </div>
            <div class="col-md-4 col-sm-6 award-body">
                <a href="#" target="_blank" class="award-item">
                    <div>
                        <span>The Future Awards Africa - Nominee</span>
                        <p class="bolder">
                            Prize for Education<br /><br />
                            <small>
                                25<sup>TH</sup> SEPTEMBER 2023
                            </small>
                        </p>
                        <img src="../assets/images/adora-tfaa.jpg" class="award-thumbnail"/>
                    </div>
                </a>
            </div>
            <div class="col-md-4 col-sm-6 award-body">
                <a href="https://voteezy.com/#/show/19/nominees/Outstanding_Woman_In_Tech_and_Engineering" target="_blank" class="award-item">
                    <div>
                        <span>AOWA Awards - Winner</span>
                        <p class="bolder">
                            Africa Outstanding Woman In Tech and Engineering<br />
                            <small>
                                1<sup>ST</sup> FEBRUARY 2023
                            </small>
                        </p>
                        <img src="../assets/images/aowa-winner.jpg" class="award-thumbnail"/>
                    </div>
                </a>
            </div>
          <div class="col-md-4 col-sm-6 award-body">
              <a href="https://www.womentech.net/nominee/Adora/Nwodo/79522" target="_blank" class="award-item">
                <div>
                    <span>Women Tech Global Awards - Winner</span>
                    <p class="bolder">
                        The 2022 WomenTech Network Community Award<br />
                        <small>
                            1<sup>ST</sup> DECEMBER 2022
                        </small>
                    </p>
                    <img src="../assets/images/womentech.jpg" class="award-thumbnail"/>
                </div>
              </a>
          </div>
         <div class="col-md-4 col-sm-6 award-body">
              <a href="https://summit.shecodeafrica.org/" target="_blank" class="award-item">
                <div>
                    <span>SheCodeAfrica - Winner</span>
                    <p class="bolder">
                        Amazon 2022<br /><br />
                        <small>
                            12<sup>TH</sup> NOVEMBER 2022
                        </small>
                    </p>
                    <img src="../assets/images/sca-min.jpg" class="award-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 award-body">
              <a href="https://ingressiveforgood.org/hackfest-tech-awards-2022/" target="_blank" class="award-item">
                <div>
                    <span>Ingressive4Good Awards - Winner</span>
                    <p class="bolder">
                        HackFest Awards Tech Woman of the Year 2022<br /><br />
                        <small>
                            12<sup>TH</sup> NOVEMBER 2022
                        </small>
                    </p>
                    <img src="../assets/images/i4gaward.png" class="award-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 award-body">
              <a href="https://www.noonies.tech/award/2021-cloud-engineering-contributor-of-the-year-2021" target="_blank" class="award-item">
                <div>
                    <span>Noonies 2021 - Winner</span>
                    <p class="bolder">
                        The 2021 Cloud Engineering Contributor of the Year<br />
                        <small>
                            14<sup>TH</sup> FEBRUARY 2022
                        </small>
                    </p>
                    <img src="../assets/images/honorary.png" class="award-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 award-body">
              <a href="https://noonies.tech/award/2021-hackernoon-contributor-of-the-year-books" target="_blank" class="award-item">
                <div>
                    <span>Noonies 2021 - Winner</span>
                    <p class="bolder">
                        Hackernoon Contributor of the Year - Books Category <br />
                        <small>
                            14<sup>TH</sup> FEBRUARY 2022
                        </small>
                    </p>
                    <img src="../assets/images/hackernoon-books.png" class="award-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 award-body">
              <a href="https://noonies.tech/award/2021-developer-defying-patriarchy-while-being-an-awesome-developer" target="_blank" class="award-item">
                <div>
                    <span>Noonies 2021 - Winner</span>
                    <p class="bolder">
                        Developer Defying Patriarchy (While being awesome)<br />
                        <small>
                            14<sup>TH</sup> FEBRUARY 2022
                        </small>
                    </p>
                    <img src="../assets/images/ddp.png" class="award-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 award-body">
              <a href="https://youngcisonetwork.com/" target="_blank" class="award-item">
                <div>
                    <span>Young CISO Network - Winner</span>
                    <p class="bolder">
                        Excellence in Disruptive Technology, Cloud and Embedded Device Security<br />
                        <small>
                            11<sup>TH</sup> MARCH 2021
                        </small>
                    </p>
                    <img src="../assets/images/ciso.jpg" class="award-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 award-body">
              <a href="https://youngcisonetwork.com/" target="_blank" class="award-item">
                <div>
                    <span>Young CISO Network - Nominee</span>
                    <p class="bolder">
                        The Young CISO of the Year 2020 (Award Finalist)<br />
                        <small>
                            11<sup>TH</sup> MARCH 2021
                        </small>
                    </p>
                    <img src="../assets/images/cisofinalist-min.jpg" class="award-thumbnail"/>
                </div>
              </a>
          </div>
      </div>
    </div>
    <FooterView />
  </div>
</template>

<script>
import SidebarView from '@/components/SidebarView.vue' 
import FooterView from '@/components/FooterView.vue' 

export default {
    components: { 
        SidebarView,
        FooterView
    }
}
</script>

<style scoped>
.award{
    width: 100%;
}
.award span{
    color: #df4aa8;
}
.award small{
    font-size: 11px;
}
.award p.bolder{
    font-weight: 400 !important;
    color: black;
}
.award .award-thumbnail{
    width: 100%;
}
.award .award-item{
    text-decoration: none !important;
}
.award .award-body{
    padding-bottom: 40px;
}
.award .award-item:hover div{
    -webkit-box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.7);
  -moz-box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.7);
  box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.7);
}
.award .award-item div{
    padding: 30px;
      -webkit-box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.3);
  border-radius: 30px;
}
</style>
