<template>
  <div class="podcast animated fadeIn">
    <div class="body">
      <div class="row">
          <div class="col-md-6 col-6">
             <SidebarView title="Podcasts" single="true" />
          </div>
          <div class="col-md-6 col-6 text-right">
            <router-link to="/">
            <img src="../assets/images/icon.jpg" class="myicon"/>
           </router-link>
        </div>
      </div>
      <br /><br />
      <div class="row">
          <div class="col-md-12">
              <h2>Featured</h2>
              <hr />
              <br />
          </div>
          <div class="col-md-4 col-sm-6 mg-bt-40">
              <a href="https://anchor.fm/simply-by-dealhqpartners/episodes/Positioning-African-Businesses-for-Revolution-4-0-e1mkdf2" target="_blank" class="podcast-item">
              <div>
                <span>Simply by DealHQ</span>
                <p class="bolder">
                    Positioning Africa for Revolution 4.0<br />
                    <small>
                        18<sup>TH</sup> AUGUST 2022
                    </small>
                </p>
                <img src="../assets/images/dealhq.jpg" class="podcast-thumbnail"/>
              </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 mg-bt-40">
              <a href="https://www.nigeriainfo.fm/lagos/podcasts/digital-life/episode/lets-talk-tech-careers-part-4/" target="_blank" class="podcast-item">
              <div>
                <span>Nigeria Info 99.3</span>
                <p class="bolder">
                    Digital Life Podcast<br />
                    <small>
                        5<sup>TH</sup> JUNE 2022
                    </small>
                </p>
                <img src="../assets/images/digital-life.jpg" class="podcast-thumbnail"/>
              </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 mg-bt-40">
              <a href="https://podcast.hackernoon.com/live-your-life-and-everyone-will-be-okay-with-adora-nwodo/" target="_blank" class="podcast-item">
              <div>
                <span>Hackernoon</span>
                <p class="bolder">
                    Hackernoon Podcast<br />
                    <small>
                        7<sup>TH</sup> DECEMBER 2021
                    </small>
                </p>
                <img src="../assets/images/hackernoon-podcast.jpg" class="podcast-thumbnail"/>
              </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 mg-bt-40">
              <a href="https://www.techsightnow.com/podcast/women-who-code-podcast-series-with-adora-nwodo" target="_blank" class="podcast-item">
              <div>
                <span>TechSightNow</span>
                <p class="bolder">
                    Women Who Code Podcast Series<br />
                    <small>
                        16<sup>TH</sup> DECEMBER 2020
                    </small>
                </p>
                <img src="../assets/images/techsightnowpod.jpg" class="podcast-thumbnail"/>
              </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 mg-bt-40">
              <a href="https://podcast.forloop.africa/episodes/ep-15-10x-developers-or-not" target="_blank" class="podcast-item">
              <div>
                <span>forloop Pod</span>
                <p class="bolder">
                    10x Developers or Not?<br />
                    <small>
                        24<sup>TH</sup> JULY 2019
                    </small>
                </p>
                <img src="../assets/images/forlooppod.jpeg" class="podcast-thumbnail"/>
              </div>
              </a>
          </div>
      </div>
    </div>
    <FooterView />
  </div>
</template>

<script>
import SidebarView from '@/components/SidebarView.vue' 
import FooterView from '@/components/FooterView.vue' 

export default {
    components: { 
        SidebarView,
        FooterView
    }
}
</script>

<style scoped>
.podcast{
    width: 100%;
}
.podcast .mg-bt-40{
    margin-bottom: 40px !important;
}
.podcast span{
    color: #df4aa8;
}
.podcast small{
    font-size: 11px;
}
.podcast p.bolder{
    font-weight: 400 !important;
    color: black;
}
.podcast .podcast-thumbnail{
    width: 100%;
}
.podcast .podcast-item{
    text-decoration: none !important;
}
.podcast .podcast-item:hover div{
    -webkit-box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.7);
  -moz-box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.7);
  box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.7);
}
.podcast .podcast-item div{
    padding: 30px;
      -webkit-box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.3);
  border-radius: 30px;
}
</style>
