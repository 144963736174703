<template>
    <div class="loading">
         <img src="../assets/images/illustration.png" style="width: 90px;" class="animated infinite fadeIn img-illustration"/>
    </div>
</template>

<style>
.loading{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgb(255,255,255) !important;
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .img-illustration{
  animation-duration: 1s;
  animation-delay: 1s;
}
</style>

