<template>
  <div id="app">
    <LoadingView v-if="loading"/>
    <router-view v-else/>
  </div>
</template>

<script>
// @ is an alias to /src
import LoadingView from '@/components/LoadingView.vue'
export default {
  components: {
    LoadingView
  }, 
  data() {
      return {
        loading: true
      }
  },
  mounted(){
    //sleep(5000);
    //console.log("Reached again")
    this.loading = false;
    
  },
  methods:{
    sleep: function(milliseconds) {
      var start = new Date().getTime();
      for (var i = 0; i < 1e7; i++) {
        if ((new Date().getTime() - start) > milliseconds){
          break;
        }
      }
    },
    WebpIsSupported: function(callback){
      // If the browser doesn't has the method createImageBitmap, you can't display webp format
      if(!window.createImageBitmap){
          callback(false);
          return;
      }

      // Base64 representation of a white point image
      var webpdata = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';

      // Retrieve the Image in Blob Format
      fetch(webpdata).then(function(response){
          return response.blob();
      }).then(function(blob){
          // If the createImageBitmap method succeeds, return true, otherwise false
          createImageBitmap(blob).then(function(){
              callback(true);
          }, function(){
              callback(false);
          });
      });
  }
  }
}
</script>

<style>
@media only screen and (max-width: 770px) {
  .body{
    padding: 20px !important;
    min-height: calc(100vh - 35px) !important;
  }
  p, a, span.page-title-1, span.page-title-2{
    font-size: 12px !important;
  }
}
.body{
  padding: 50px;
  min-height: calc(100vh - 50px)
}
.myicon{
  width: 50px; 
  border-radius: 50px;
  border: 1px dashed #c12688;
}
@media only screen and (min-width: 768px) {
  h1{font-size: 80px !important;}
}
@media only screen and (min-width: 600px) {
  h1{font-size: 70px !important;}
}
@media only screen and (max-width: 599px) {
  h1{font-size: 50px !important;}
}
@media only screen and (max-width: 480px) {
  h1{font-size: 25px !important;}
  p, a, span.page-title-1, span.page-title-2{font-size: 11px !important;}
}
h1, h3, h4, h5, h6{font-family: 'Poppins', sans-serif !important; font-weight: 900 !important;}
h2{font-family: 'Poppins', sans-serif !important; font-weight: 400 !important;}
p, li, span, a{font-family: 'Poppins', sans-serif; font-weight: 300 !important;}
/*p a, p a:hover, h3 a, h3 a:hover{color: #c12688;}*/
</style>
