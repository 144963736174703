<template>
    <footer>
        <div class="row">
            <div class="col-12 text-center">
                <p><small>Copyright Adora Nwodo {{this.year}} &copy;</small></p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  data() {
    return {
        year: new Date().getFullYear()
    }
  }
}
</script>

<style>
footer{width: 100%;}
footer p {margin-bottom: 15px}
</style>

