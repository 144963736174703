<template>
  <div class="feature animated fadeIn">
    <div class="body">
      <div class="row">
          <div class="col-md-6 col-6">
             <SidebarView title="Features" single="true" />
          </div>
          <div class="col-md-6 col-6 text-right">
            <router-link to="/">
            <img src="../assets/images/icon.jpg" class="myicon" />
           </router-link>
        </div>
      </div>
      <br /><br />
      <div class="row">
        <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://theciotimes.com/magazine/Top-Five-Most-Effective-Business-Leaders-Shaping-the-Modern-Industry/#page=1" target="_blank" class="feature-item">
                <div>
                    <span>CIO Times</span>
                    <p class="bolder">
                        Magazine Feature<br />
                        <small>
                            DECEMBER 2023
                        </small>
                    </p>
                    <img src="../assets/images/ciotimes-min.png" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://theciomedia.com/women-in-power-driving-change-and-innovation-in-business-2023-digital-version/" target="_blank" class="feature-item">
                <div>
                    <span>CIO Media</span>
                    <p class="bolder">
                        Magazine Feature<br />
                        <small>
                            DECEMBER 2023
                        </small>
                    </p>
                    <img src="../assets/images/ciomedia-min.png" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://www.logitech.com/en-us/mx/adora-nwodo.html" target="_blank" class="feature-item">
                <div>
                    <span>Logitech</span>
                    <p class="bolder">
                        Feature<br />
                        <small>
                            17<sup>TH</sup> JULY 2023
                        </small>
                    </p>
                    <img src="../assets/images/logitech-min.png" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://enterpriseceo.ng/2023/07/11/from-childhood-curiosity-to-microsoft-adaobi-okafors-inspiring-journey-as-a-software-engineer/" target="_blank" class="feature-item">
                <div>
                    <span>Enterprise CEO</span>
                    <p class="bolder">
                        Feature<br />
                        <small>
                            11<sup>TH</sup> JULY 2023
                        </small>
                    </p>
                    <img src="../assets/images/enterprise-ceo-min.png" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://www.cxomagazine.com/building-and-advocating-for-mixed-reality-technologies/" target="_blank" class="feature-item">
                <div>
                    <span>CXO Magazine</span>
                    <p class="bolder">
                        Feature<br />
                        <small>
                            25<sup>TH</sup> APRIL 2023
                        </small>
                    </p>
                    <img src="../assets/images/cxomag-min.png" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://we-rule.com/female-founders/2023/3/25/adora-nwodo-multi-award-winning-software-engineer-and-the-author-of-two-books" target="_blank" class="feature-item">
                <div>
                    <span>WeRule</span>
                    <p class="bolder">
                        Feature<br />
                        <small>
                            27<sup>TH</sup> MARCH 2023
                        </small>
                    </p>
                    <img src="../assets/images/we-rule.png" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://www.bellanaija.com/2023/03/sla-announces-top-50-rising-motherland-moguls/" target="_blank" class="feature-item">
                <div>
                    <span>She Leads Africa</span>
                    <p class="bolder">
                        Top 50 Rising Motherland Moguls<br />
                        <small>
                            10<sup>TH</sup> MARCH 2023
                        </small>
                    </p>
                    <img src="../assets/images/bn-iwd.png" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://wazoplus.com/article/top-18-leading-women-voices-in-tech-in-africa-cb9d1215" target="_blank" class="feature-item">
                <div>
                    <span>Wazoplus</span>
                    <p class="bolder">
                        Top 18 Leading Women In African Tech<br />
                        <small>
                            8<sup>TH</sup> MARCH 2023
                        </small>
                    </p>
                    <img src="../assets/images/wazoplus.png" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://www.thisdaylive.com/index.php/2023/03/08/2023-iwd-celebrating-successful-nigerian-women-in-tech/" target="_blank" class="feature-item">
                <div>
                    <span>ThisDay Newspaper</span>
                    <p class="bolder">
                        Successful Nigerian Women in Tech<br />
                        <small>
                            8<sup>TH</sup> MARCH 2023
                        </small>
                    </p>
                    <img src="../assets/images/thisday-iwd-min.png" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://medium.com/opentraction/international-womens-day-2023-embraceequity-in-the-african-tech-ecosystem-a9dc616baa3b" target="_blank" class="feature-item">
                <div>
                    <span>Microtraction</span>
                    <p class="bolder">
                        Embracing Equity #IWD2023<br />
                        <small>
                            8<sup>TH</sup> MARCH 2023
                        </small>
                    </p>
                    <img src="../assets/images/microtration-iwd-min.png" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://www.digitalfirstmagazine.com/a-guide-for-businesses-best-practices-to-secure-cloud-infrastructure/" target="_blank" class="feature-item">
                <div>
                    <span>Digital First Magazine</span>
                    <p class="bolder">
                        Practices to Secure Cloud Infrastructure<br />
                        <small>
                            21<sup>ST</sup> FEBRUARY 2023
                        </small>
                    </p>
                    <img src="../assets/images/digitalfirstmagazine.png" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://www.educative.io/blog/educative-technical-writing-workshop-nigeria-winners" target="_blank" class="feature-item">
                <div>
                    <span>Educative</span>
                    <p class="bolder">
                        Technical Writing Workshop in Nigeria<br />
                        <small>
                            14<sup>TH</sup> FEBRUARY 2023
                        </small>
                    </p>
                    <img src="../assets/images/educative-winners-min.png" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://www.cxooutlook.com/cloud-computing-and-its-impact-on-business-operations/" target="_blank" class="feature-item">
                <div>
                    <span>CXO Outlook</span>
                    <p class="bolder">
                        Cloud Computing in Business Operations<br />
                        <small>
                            31<sup>ST</sup> JANUARY 2023
                        </small>
                    </p>
                    <img src="../assets/images/cxo-outlook-min.png" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://www.educative.io/blog/educative-answers-spotlight-blog-adora-nwodo" target="_blank" class="feature-item">
                <div>
                    <span>Educative</span>
                    <p class="bolder">
                        Interview with Adora<br />
                        <small>
                            27<sup>TH</sup> JANUARY 2023
                        </small>
                    </p>
                    <img src="../assets/images/answers-interview-min.png" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://www.womentech.net/blog/women-in-tech-global-awards-voices-2022-winner-community-award" target="_blank" class="feature-item">
                <div>
                    <span>WomenTech Network</span>
                    <p class="bolder">
                        Global Awards Winners Feature<br />
                        <small>
                            13<sup>TH</sup> JANUARY 2023
                        </small>
                    </p>
                    <img src="../assets/images/women-tech-feature-min.png" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://techpoint.africa/2022/12/26/2022-for-nigerian-women-in-tech" target="_blank" class="feature-item">
                <div>
                    <span>Techpoint</span>
                    <p class="bolder">
                        Women in Tech 2022 Review<br />
                        <small>
                            26<sup>TH</sup> DECEMBER 2022
                        </small>
                    </p>
                    <img src="../assets/images/techpoint-year-in-review-min.png" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://lekkipost.com/2022/12/24/adora-a-shinning-star-in-nigerias-booming-tech-industry/" target="_blank" class="feature-item">
                <div>
                    <span>The Lekki Post</span>
                    <p class="bolder">
                        A Star In Nigeria's Tech Industry<br />
                        <small>
                            24<sup>TH</sup> DECEMBER 2022
                        </small>
                    </p>
                    <img src="../assets/images/lekki-post-min.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://www.youtube.com/watch?v=u4g5gwg7-A8" target="_blank" class="feature-item">
                <div>
                    <span>The Leaderboard - Fisayo Fosudo</span>
                    <p class="bolder">
                        How To Get A High Paying Tech Job<br />
                        <small>
                            23<sup>RD</sup> DECEMBER 2022
                        </small>
                    </p>
                    <img src="../assets/images/ff-min.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://www.youtube.com/watch?v=C_fG6WezBY8" target="_blank" class="feature-item">
                <div>
                    <span>AriseTV</span>
                    <p class="bolder">
                        Exploring Cloud Careers<br />
                        <small>
                            18<sup>TH</sup> NOVEMBER 2022
                        </small>
                    </p>
                    <img src="../assets/images/arise-min.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://blog.piggyvest.com/life/how-adora-nwodo-went-from-%e2%82%a615k-month-to-a-microsoft-job-a-book-deal/" target="_blank" class="feature-item">
                <div>
                    <span>PiggyVest Blog</span>
                    <p class="bolder">
                        Interview with Adora<br />
                        <small>
                            15<sup>TH</sup> NOVEMBER 2022
                        </small>
                    </p>
                    <img src="../assets/images/piggyvest-min.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://issuu.com/thisdaylive/docs/tdla_1114_411847a9218ef7" target="_blank" class="feature-item">
                <div>
                    <span>ThisDay Newspaper</span>
                    <p class="bolder">
                        Tech Personality of the Week<br />
                        <small>
                            14<sup>TH</sup> NOVEMBER 2022
                        </small>
                    </p>
                    <img src="../assets/images/thisday2-min.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://technext.ng/2022/10/18/adora-nwodo-changing-tech-digital-space/" target="_blank" class="feature-item">
                <div>
                    <span>Technext</span>
                    <p class="bolder">
                        Interview with Adora<br />
                        <small>
                            23<sup>RD</sup> OCTOBER 2022
                        </small>
                    </p>
                    <img src="../assets/images/technext-min.png" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://techcabal.com/2022/10/19/entering-tech-008-cloud-engineer/" target="_blank" class="feature-item">
                <div>
                    <span>TechCabal</span>
                    <p class="bolder">
                        Entering Tech - Cloud Engineering<br />
                        <small>
                            19<sup>TH</sup> OCTOBER 2022
                        </small>
                    </p>
                    <img src="../assets/images/entering-tech.png" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://breach.club/articles/7-lessons-a-25-year-old-learned-on-her-journey-to-landing-a-microsoft-job" target="_blank" class="feature-item">
                <div>
                    <span>Breach</span>
                    <p class="bolder">
                        Level Up Series<br />
                        <small>
                            27<sup>TH</sup> SEPTEMBER 2022
                        </small>
                    </p>
                    <img src="../assets/images/breach.png" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://www.channelstv.com/2022/06/10/first-class-adora-nwodo-is-the-dj-and-maths-whiz-creating-new-worlds-at-microsoft/" target="_blank" class="feature-item">
                <div>
                    <span>ChannelsTV</span>
                    <p class="bolder">
                        First Class Series<br />
                        <small>
                            10<sup>TH</sup> JUNE 2022
                        </small>
                    </p>
                    <img src="../assets/images/channelsinterview.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://techcabal.com/2022/05/17/adora-nwodos-journey-into-mixed-reality-and-cloud-engineering/" target="_blank" class="feature-item">
                <div>
                    <span>TechCabal</span>
                    <p class="bolder">
                        Centre Stage<br />
                        <small>
                            17<sup>TH</sup> MAY 2022
                        </small>
                    </p>
                    <img src="../assets/images/tc-centerstage.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://www.techpoint.africa/2022/04/12/adora-nwodo-microsoft-engineer" target="_blank" class="feature-item">
                <div>
                    <span>Techpoint</span>
                    <p class="bolder">
                        The Experts Interview<br />
                        <small>
                            12<sup>TH</sup> APRIL 2022
                        </small>
                    </p>
                    <img src="../assets/images/techpoint.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://olorisupergal.com/2022/03/24/9to5chick-top-100-career-women-in-nigeria-2022/" target="_blank" class="feature-item">
                <div>
                    <span>OloriSuperGal Blog</span>
                    <p class="bolder">
                        Top 100 Career Women in Nigeria<br />
                        <small>
                            24<sup>TH</sup> MARCH 2022
                        </small>
                    </p>
                    <img src="../assets/images/95chick.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://punchng.com/i-spent-most-of-my-childhood-in-front-of-computer-nwodo/" target="_blank" class="feature-item">
                <div>
                    <span>Punch Newspaper</span>
                    <p class="bolder">
                        Interview with Adora<br />
                        <small>
                            20<sup>TH</sup> MARCH 2022
                        </small>
                    </p>
                    <img src="../assets/images/punch.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://firstcheckhq.medium.com/firstcheckrisingstars-bbb3bee4fe12" target="_blank" class="feature-item">
                <div>
                    <span>FirstCheck Africa</span>
                    <p class="bolder">
                        Rising Stars: Women in Tech<br />
                        <small>
                            14<sup>TH</sup> FEBRUARY 2022
                        </small>
                    </p>
                    <img src="../assets/images/firstcheck2.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://www.thisdaylive.com/index.php/2022/01/31/2022-nigerian-women-in-tech-break-new-grounds/" target="_blank" class="feature-item">
                <div>
                    <span>ThisDay Newspaper</span>
                    <p class="bolder">
                        Women in Tech Break New Grounds<br />
                        <small>
                            31<sup>ST</sup> JANUARY 2022
                        </small>
                    </p>
                    <img src="../assets/images/thisdaynews.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://hackernoon.com/an-insider-view-of-mesh-microsofts-entry-into-mixed-reality-applications" target="_blank" class="feature-item">
                <div>
                    <span>Hackernoon</span>
                    <p class="bolder">
                        An Insider View of Mesh<br />
                        <small>
                            18<sup>TH</sup> NOVEMBER 2021
                        </small>
                    </p>
                    <img src="../assets/images/hackernoon.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://blacktechsource.com/black-tech-source-interview-with-software-engineer-author-adora-nwodo/" target="_blank" class="feature-item">
                <div>
                    <span>BlackTechSource</span>
                    <p class="bolder">
                        Interview with Adora Nwodo<br />
                        <small>
                            17<sup>TH</sup> NOVEMBER 2021
                        </small>
                    </p>
                    <img src="../assets/images/black-tech-source.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://scigi1.home.blog/2021/11/17/adora-nwodo-now-more-than-ever-cloud-engineering-is-an-in-demand-field/" target="_blank" class="feature-item">
                <div>
                    <span>SciGi Blog</span>
                    <p class="bolder">
                        Interview with Adora Nwodo<br />
                        <small>
                            17<sup>TH</sup> NOVEMBER 2021
                        </small>
                    </p>
                    <img src="../assets/images/scigi.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://www.youtube.com/watch?v=nUJj5R5ywdE" target="_blank" class="feature-item">
                <div>
                    <span>Channels TV</span>
                    <p class="bolder">
                        How To Get More Women In S.T.E.M<br />
                        <small>
                            15<sup>TH</sup> NOVEMBER 2021
                        </small>
                    </p>
                    <img src="../assets/images/channels.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://leadingladiesafrica.org/llatechthursdays-meet-adora-nwodo-software-engineer-at-microsoft-and-co-founder-of-unstack-africa/" target="_blank" class="feature-item">
                <div>
                    <span>Leading Ladies Africa</span>
                    <p class="bolder">
                        #LLATechThursdays<br />
                        <small>
                            28<sup>TH</sup> OCTOBER 2021
                        </small>
                    </p>
                    <img src="../assets/images/lla.png" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://businessday.ng/life-arts/article/adaora-nwodo-young-techy-and-honing-her-skills/" target="_blank" class="feature-item">
                <div>
                    <span>BusinessDayNG</span>
                    <p class="bolder">
                        Women's Hub Series<br />
                        <small>
                            15<sup>TH</sup> OCTOBER 2021
                        </small>
                    </p>
                    <img src="../assets/images/businessday.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://www.benjamindada.com/cloud-engineering-for-beginners-adora-nwodo/" target="_blank" class="feature-item">
                <div>
                    <span>Benjamin Dada</span>
                    <p class="bolder">
                        Adora's Cloud Engineering Book<br />
                        <small>
                            7<sup>TH</sup> OCTOBER 2021
                        </small>
                    </p>
                    <img src="../assets/images/ceb-dadaben.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://ynaija.com/ytech100-miss-techy-dare-adekoya-mark-essien-mopewa-ogundipe-ada-nduka-oyom-ada-nduka-oyom-see-most-influential-nigerians-in-tech/8/" target="_blank" class="feature-item">
                <div>
                    <span>#YTech100</span>
                    <p class="bolder">
                        Top Influential Nigerians in Tech<br />
                        <small>
                            5<sup>TH</sup> OCTOBER 2021
                        </small>
                    </p>
                    <img src="../assets/images/ytech100.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://womenintech.atingi.org/women-in-tech/adora-nwodo/" target="_blank" class="feature-item">
                <div>
                    <span>Atingi</span>
                    <p class="bolder">
                        #WomenRulingTech<br />
                        <small>
                            2<sup>ND</sup> JULY 2021
                        </small>
                    </p>
                    <img src="../assets/images/atingi.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://www.africabusinessradio.com/" target="_blank" class="feature-item">
                <div>
                    <span>Africa Business Radio</span>
                    <p class="bolder">
                        Upscaling Software Engineering<br />
                        <small>
                            18<sup>TH</sup> MAY 2021
                        </small>
                    </p>
                    <img src="../assets/images/abr.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://www.benjamindada.com/10-female-tech-professionals-africa/" target="_blank" class="feature-item">
                <div>
                    <span>Benjamin Dada</span>
                    <p class="bolder">
                        Female Nigerian tech professionals<br />
                        <small>
                            14<sup>TH</sup> MAY 2021
                        </small>
                    </p>
                    <img src="../assets/images/dadaben1.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://ennovatelab.substack.com/p/how-i-use-tech-adora-nwodo-software" target="_blank" class="feature-item">
                <div>
                    <span>Ennovate Lab</span>
                    <p class="bolder">
                        #HowIUseTech<br />
                        <small>
                            7<sup>TH</sup> APRIL 2021
                        </small>
                    </p>
                    <img src="../assets/images/ennovate.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://careerbuddyblog.com/?p=1472" target="_blank" class="feature-item">
                <div>
                    <span>Career Buddy</span>
                    <p class="bolder">
                        5 Questions with 3 Women Killing it in Tech<br />
                        <small>
                            9<sup>TH</sup> MARCH 2021
                        </small>
                    </p>
                    <img src="../assets/images/careerbuddy.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://firstcheckhq.medium.com/choosetochallenge-30-nigerian-women-in-tech-challenging-the-status-quo-71c17053832a" target="_blank" class="feature-item">
                <div>
                    <span>FirstCheck Africa</span>
                    <p class="bolder">
                        #ChooseToChallenge<br />
                        <small>
                            8<sup>TH</sup> MARCH 2021
                        </small>
                    </p>
                    <img src="../assets/images/firstcheck.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://zoom.us/webinar/register/WN_NAwsXr85T761QezJOPmvLw" target="_blank" class="feature-item">
                <div>
                    <span>Bamboo Sticks</span>
                    <p class="bolder">
                        Starting a Career in Tech<br />
                        <small>
                            11<sup>TH</sup> JULY 2020
                        </small>
                    </p>
                    <img src="../assets/images/bamboo-sticks.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a class="feature-item">
                <div>
                    <span>Microsoft Learn TV</span>
                    <p class="bolder">
                        92 & Pike (Co-host)<br />
                        <small>
                            21<sup>ST</sup> MAY 2020
                        </small>
                    </p>
                    <img src="../assets/images/learntv.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://www.builtinafrica.io/blog-post/fashion-e-commerce-building-a-platform-for-made-in-nigeria-products" target="_blank" class="feature-item">
              <div>
                <span>Built in Africa</span>
                <p class="bolder">
                    Building Fort NG<br />
                    <small>
                        12<sup>TH</sup> APRIL 2020
                    </small>
                </p>
                <img src="../assets/images/bia.jpg" class="feature-thumbnail"/>
              </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a href="https://townhall.hashnode.com/women-in-tech-nenne-adaora-nwodo-ck2u1s1o200yij1s1ymdofyiw" target="_blank" class="feature-item">
              <div>
                <span>Hashnode</span>
                <p class="bolder">
                    She Inspires<br />
                    <small>
                        11<sup>TH</sup> NOVEMBER 2019
                    </small>
                </p>
                <img src="../assets/images/hashnode.jpg" class="feature-thumbnail"/>
              </div>
              </a>
          </div>
          <div class="col-md-4 col-sm-6 feature-body">
              <a class="feature-item">
                <div>
                    <span>Nigeria Info FM Lagos</span>
                    <p class="bolder">
                        Women Inclusion in Nigerian Tech<br />
                        <small>
                            19<sup>TH</sup> MAY 2019
                        </small>
                    </p>
                    <img src="../assets/images/nigeriainfo.jpg" class="feature-thumbnail"/>
                </div>
              </a>
          </div>
      </div>
    </div>
    <FooterView />
  </div>
</template>

<script>
import SidebarView from '@/components/SidebarView.vue' 
import FooterView from '@/components/FooterView.vue' 

export default {
    components: { 
        SidebarView,
        FooterView
    }
}
</script>

<style scoped>
.feature{
    width: 100%;
}
.feature span{
    color: #df4aa8;
}
.feature small{
    font-size: 11px;
}
.feature p.bolder{
    font-weight: 400 !important;
    color: black;
}
.feature .feature-thumbnail{
    width: 100%;
}
.feature .feature-item{
    text-decoration: none !important;
}
.feature .feature-body{
    padding-bottom: 40px;
}
.feature .feature-item:hover div{
    -webkit-box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.7);
  -moz-box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.7);
  box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.7);
}
.feature .feature-item div{
    padding: 30px;
      -webkit-box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.3);
  border-radius: 30px;
}
</style>
