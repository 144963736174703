<template>
    <div class="book animated fadeIn">
      <div class="body">
        <div class="row">
            <div class="col-md-6 col-6">
               <SidebarView title="Beginning Azure DevOps" previous="Books and Courses" previousLink="/books-and-courses" />
            </div>
            <div class="col-md-6 col-6 text-right">
                <router-link to="/">
                    <img src="../assets/images/icon.jpg" class="myicon" />
                </router-link>
          </div>
        </div>
        <br /><br />
        <div class="row">
              <div class="col-md-1 col-sm-2"></div>
              <div class="col-md-10 col-sm-8">
                  <div class="row">
                      <div class="col-md-5 col-sm-12">
                          <img src="../assets/images/beginning-ado-mockup.png" style="width: 100%;" />    
                      </div>
                      <div class="col-md-1 col-sm-12"></div>
                      <div class="col-md-6 col-sm-12">
                          <h1 class="book-title">
                              BEGINNING<br />
                              AZURE DEVOPS
                          </h1>
                          <p>Planning, Building, Testing and Releasing
                            <br />Software Applications on Azure</p>
                          <br />
                          <p class="purple">by Adora Nwodo</p>
                      </div>
                  </div>
                  <br /><br /><br />
                  <div class="row">
                      <div class="col-sm-12 text-center">
                          <h4>Buy Now On:</h4>
                      </div>
                      <div class="col-sm-12 text-center">
                        <img src="../assets/images/wiley.jpg" class="platform-logo" /> 
                        <img src="../assets/images/amazon.png" class="platform-logo" />
                        <img src="../assets/images/bn.jpg" class="platform-logo" />
                        <img src="../assets/images/indigo_logo.png" class="platform-logo" />
                        <img src="../assets/images/waterstones.jpeg" class="platform-logo" />  
                      </div>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col-sm-12 text-center">
                        <a class="btn preorder" href="https://www.wiley.com/en-us/Beginning+Azure+DevOps%3A+Planning%2C+Building%2C+Testing+and+Releasing+Software+Applications+on+Azure-p-9781394165889" target="_blank">Buy on Wiley</a>
                        <a class="btn preorder" href="https://www.amazon.com/Beginning-Azure-DevOps-Releasing-Applications/dp/1394165889/ref=sr_1_1?crid=1929JQLNIQ4T9&keywords=Beginning+Azure+DevOps%3A+Planning%2C+Building%2C+Testing+and+Releasing+Software+Applications+on+Azure&qid=1667591683&s=books&sprefix=beginning+azure+devops+planning%2C+building%2C+testing+and+releasing+software+applications+on+azure%2Cstripbooks%2C100&sr=1-1" target="_blank">Buy on Amazon</a>
                        <a class="btn preorder" href="https://www.barnesandnoble.com/w/beginning-azure-devops-adora-nwodo/1142272288?ean=9781394165889" target="_blank">Buy on Barnes & Noble</a>
                        <a class="btn preorder" href="https://www.chapters.indigo.ca/en-ca/books/beginning-azure-devops-planning-building/9781394165889-item.html?ikwid=adora+nwodo&ikwsec=Home&ikwidx=0#algoliaQueryId=be2331c98a13e6c64c618eaaca072a1b" target="_blank">Buy on Indigo (Canada)</a>
                        <a class="btn preorder" href="https://www.waterstones.com/book/beginning-azure-devops-planning-building-testin-g-and-releasing-software-applications-on-azure/nwodo/9781394165889" target="_blank">Buy on Waterstones (UK)</a>
                    </div>
                  </div>
                  <br /><br />
                  <div class="row">
                      <div class="col-sm-2"></div>
                      <div class="col-sm-8">
                          <h2 class="quote text-center">"The most powerful tool we have as developers is automation" ~ Scott Hanselman</h2>
                      </div>
                      <div class="col-sm-2"></div>
                  </div>
                  <br /><br />
                  <div class="row">
                      <div class="col-sm-1"></div>
                      <div class="col-sm-10">
                          <p>
                            In <em>Beginning Azure DevOps: Planning, Building, Testing and Releasing Software Applications on Azure</em>, award-winning software engineer 
                            Adora Nwodo delivers a beginner's guide to DevOps on the Microsoft-powered Azure cloud platform. In the book, you'll learn to deploy Azure's 
                            built-in DevOps tools required to plan, build, test, and ship applications. <br /><br />
                            The author explains how to use Azure's functionality for project management, version control, code testing, and continuous integration and 
                            continuous delivery (CI/CD). She discusses how to plan software development projects from start to finish using Agile and Scrum techniques. 
                            Readers will also find:
                          </p>
                          <ul class="what-to-learn">
                            <li>
                                Updated source code repositories on GitHub with instructive practice exercises
                            </li>
                            <li>
                                Strategies for improving collaboration and automating your code-to-cloud workflows
                            </li>
                            <li>
                                Techniques for securing your code with advanced capabilities
                            </li>
                          </ul>
                          <p>
                            An essential resource for Azure novices who hope to learn about the myriad possibilities unlocked by Azure DevOps tools, Beginning Azure DevOps 
                            will also earn a place in the libraries of software professionals familiar with other cloud platforms who hope to gain a foothold in the Azure cloud environment.
                          </p>
                      </div>
                      <div class="col-sm-1"></div>
                  </div>
                  <div class="row">
                      <div class="col-md-12 text-center">
                          <br /><br />
                          <h3 class="purple">GET THIS BOOK NOW!</h3>
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12 text-center">
                        <a class="btn preorder" href="https://www.wiley.com/en-us/Beginning+Azure+DevOps%3A+Planning%2C+Building%2C+Testing+and+Releasing+Software+Applications+on+Azure-p-9781394165889" target="_blank">Buy on Wiley</a>
                        <a class="btn preorder" href="https://www.amazon.com/Beginning-Azure-DevOps-Releasing-Applications/dp/1394165889/ref=sr_1_1?crid=1929JQLNIQ4T9&keywords=Beginning+Azure+DevOps%3A+Planning%2C+Building%2C+Testing+and+Releasing+Software+Applications+on+Azure&qid=1667591683&s=books&sprefix=beginning+azure+devops+planning%2C+building%2C+testing+and+releasing+software+applications+on+azure%2Cstripbooks%2C100&sr=1-1" target="_blank">Buy on Amazon</a>
                        <a class="btn preorder" href="https://www.barnesandnoble.com/w/beginning-azure-devops-adora-nwodo/1142272288?ean=9781394165889" target="_blank">Buy on Barnes & Noble</a>
                        <a class="btn preorder" href="https://www.chapters.indigo.ca/en-ca/books/beginning-azure-devops-planning-building/9781394165889-item.html?ikwid=adora+nwodo&ikwsec=Home&ikwidx=0#algoliaQueryId=be2331c98a13e6c64c618eaaca072a1b" target="_blank">Buy on Indigo (Canada)</a>
                        <a class="btn preorder" href="https://www.waterstones.com/book/beginning-azure-devops-planning-building-testin-g-and-releasing-software-applications-on-azure/nwodo/9781394165889" target="_blank">Buy on Waterstones (UK)</a>
                    </div>
                  </div>
                  <br /><br />
                  <hr />
                  <br />
                  <div class="row">
                      <div class="col-md-12">
                          <br /><br />
                          <h3 class="purple text-center">ABOUT THE AUTHOR</h3>
                          <br /><br />
                      </div>
                      <div class="col-md-3 author-container">
                          <img src="../assets/images/nenne-min.jpg" class="full-width" />
                      </div>
                      <div class="col-md-9">
                          <p>
                              <router-link to="/" class="txt-black custom-link">Adora Nwodo</router-link> is a Senior Software Engineer currently working
                              at the intersection of Cloud Engineering and Developer Platforms. She is also the Founder of NexaScale, a non-profit helping people
                              start and scale their tech careers, and she is a Vice President of the Virtual Reality/Augmented Reality (VRAR)
                              Association – Nigeria chapter.
                              <br /><br />
                              With a passion for enabling women in technology, Adora publishes content to share her tech journey and teach
                              software engineering on her blog called AdoraHack.
                          </p>
                      </div>
                  </div>
              </div>
              <div class="col-md-1 col-sm-2"></div>
        </div>
        <br /><br /><br />
      </div>
      <FooterView />
    </div>
  </template>
  
  <script>
  import SidebarView from '@/components/SidebarView.vue' 
  import FooterView from '@/components/FooterView.vue' 
  
  export default {
      components: { 
          SidebarView,
          FooterView 
      }
  }
  </script>
  
  <style scoped>
  .book{
      width: 100%;
  }
  .what-to-learn{
    margin-left: 20px !important;
  }
  a.preorder.btn{
    font-family: 'Poppins', sans-serif; font-weight: 300 !important;
    font-size: 17px !important;
  }
  .full-width{
      width: 100%;
  }
  .platform-logo{
      height: 30px !important;
      margin: 10px 20px !important;
  }
  .book-mockup{
      margin-bottom: 20px;
  }
  .book .purple{
    color: rgb(215, 0, 215);
  }
  .book .orange{
      color: #FF914D;
  }
  .book .contributor{
      border-top-left-radius: 40px;
      border-bottom-right-radius: 40px;
      border: 4px dotted #FF914D;
      padding: 10px;
  }
  .book h5 a{
      font-weight: bolder !important;
      color: black !important;
  }
  .book ul li{
      margin-left: -20px;
      padding-bottom: 5px;
  }
  .contributor-container h5{
      margin-top: 15px !important;
  }
  .contributor-container p{
      margin-bottom: 25px !important;
  }
  .orange-bg{
      background: #FF914D !important;
  }
  .txt-black{
      color: black !important;
  }
  h3.break{
      font-size: 22px !important;
  }
  a.preorder{
      background: black;
      font-weight: 400 !important;
      padding: 10px 20px;
      border-radius: 20px;
      color: white;
      font-size: 18px !important;
      border: 2px solid black;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 20px;
  }
  a.preorder:hover{
      box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
      -webkit-box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
      -moz-box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
      background: white;
      color: black;
  }
  a.preorder-black{
      background: #000;
      font-weight: 400 !important;
      padding: 10px 20px;
      border-radius: 20px;
      color: white;
      font-size: 18px !important;
      border: 2px solid #000;
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 20px;
  }
  a.preorder-black:hover{
      box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
      -webkit-box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
      -moz-box-shadow: -4px 5px 17px -2px rgba(0,0,0,0.29);
      background: white;
      color: #000;
  }
  .book .author-container img{
      padding-bottom: 30px;
  }
  .book h1.book-title span{
      font-weight: 900 !important;
  }
  .book h2.quote{
      font-family: 'Open Sans', sans-serif;
      color: #ddd;
      font-style: italic;
  }
  .book small{
      font-size: 11px;
  }
  .book p, .book .what-to-learn{
      font-size: 18px !important;
  }
  .book p.bolder{
      font-weight: 400 !important;
      color: black;
  }
  @media only screen and (min-width: 1200px) {
    .book h1.book-title{font-size: 45px !important; padding-top: 50px;}
  }
  @media only screen and (max-width: 1199px) {
    .book h1.book-title{font-size: 35px !important; padding-top: 30px;}
  }
  @media only screen and (max-width: 900px) {
    .book h1.book-title{font-size: 28px !important; padding-top: 35px;}
  }
  @media only screen and (max-width: 869px) {
    p{font-size: 11px !important;}
    .book h2.quote{font-size: 24px !important;}
  }
  @media only screen and (max-width: 767px) {
    .book h1.book-title{font-size: 50px !important; padding-top: 35px; text-align: center;}
    p{font-size: 20px !important; text-align: center;}
    p a.custom-link, .book h5 a{ font-size: 18px !important; }
  }
  @media only screen and (max-width: 599px) {
    .book h1.book-title{font-size: 50px !important; padding-top: 40px; text-align: center;}
    p a.custom-link, .book h5 a{ font-size: 18px !important; }
  }
  @media only screen and (max-width: 480px) {
    .book h1.book-title{font-size: 50px !important; padding-top: 30px; text-align: center;}
     p{font-size: 15px !important; text-align: center;}
     p a.custom-link, .book h5 a{ font-size: 18px !important; }
  }
  @media only screen and (max-width: 390px) {
    .book h1.book-title{font-size: 40px !important; padding-top: 30px; text-align: center;}
  }
  </style>
  