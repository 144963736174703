<template>
  <div class="community animated fadeIn">
    <div class="body">
      <div class="row">
          <div class="col-md-6 col-6">
             <SidebarView title="Community" single="true" />
          </div>
          <div class="col-md-6 col-6 text-right">
            <router-link to="/">
            <img src="../assets/images/icon.jpg" class="myicon" />
           </router-link>
        </div>
      </div>
      <br /><br />
      <div class="row">
          <div class="col-md-6 col-sm-12">
              <h4>AdoraHack Study Groups</h4>
              <p>
                  <strong>Role: Creator</strong>
              </p>
              <p>
                <a href="https://bit.ly/adorahack-study-groups" target="_blank" rel="noopener">AdoraHack Study Groups</a> is an online community for people to ask questions, share, learn, collaborate and grow together.
                As people in tech, we struggle sometimes and having peers and experts to ask for help and get answers to technical questions is priceless.
              </p>
              <p>
                Tracks include: Data Structures and Algorithms, Frontend, Backend, Mobile, Cloud and Data Engineering ✨
              </p>
          </div>
          <div class="col-md-6 col-sm-12">
              <h4>VRARA Nigeria</h4>
              <p>
                  <strong>Role: Advisory Board Member</strong>
              </p>
              <p>The <a href="https://www.thevrara.com/nigeria" target="_blank" rel="noopener">VRAR Association</a> is the global member community of the best minds in virtual reality and 
                  augmented reality with chapters in major cities across the globe.</p>
              <p>As an Advisory board member, I help drive the enthusiasm for XR in this region by planning XR events and speaking at other events gotten through the community network.</p>
          </div>
      </div>
      <hr />
      <div class="row">
            <div class="col-md-6 col-sm-12">
              <h4>unStack</h4>
              <p>
                  <strong>Role: CoFounder</strong>
              </p>
              <p>
                <a href="https://twitter.com/unStackAfrica" target="_blank" rel="noopener">unStack</a> is an open-source meetup or conference for every software developer where learning is 
                done on a whole other level through hands-on workshops and world-class talks.
              </p>
              <p>
                  We started in Lagos, Nigeria and are currently scaling to other countries in Africa and 
                  other parts of the world. I help co-organize and speak at unStack events. 
                  I am also currently managing relationships with our different unStack chapters across Africa.
              </p>
          </div>
          <div class="col-md-6 col-sm-12">
              <h4>Microsoft LEAP</h4>
              <p>
                  <strong>Role: Mentor</strong>
              </p>
              <p><a href="https://www.microsoft.com/en-us/leap/" target="_blank" rel="noopener">LEAP Engineering Acceleration Program (LEAP)</a> is an immersive, 16-week program providing apprentices with real-world experience 
                  through development and project management apprenticeships. The goal is to train people who may have a non-traditional 
                  background or returning from time away from the workplace and help them start their tech journey.</p>
              <p>I volunteered as a mentor for 2 LEAP cohorts. Volunteering as a mentor, I get to help apprentices in the 16-weeks of the 
                  program technically and also with career tips and other helpful resources they might need.</p>
          </div>
      </div>
    </div>
    <FooterView />
  </div>
</template>

<script>
import SidebarView from '@/components/SidebarView.vue' 
import FooterView from '@/components/FooterView.vue' 

export default {
    components: { 
        SidebarView,
        FooterView
    }
}
</script>

<style scoped>
.community .col-md-6{
    margin-bottom: 40px;
}
.community .community-pic{
    width: 100%;
    border-radius: 50px;
    -webkit-box-shadow: 0px 6px 26px -15px rgba(0, 0, 0, 0.65);
-moz-box-shadow: 0px 6px 26px -15px rgba(0,0,0,0.65);
box-shadow: 0px 6px 26px -15px rgba(0,0,0,0.65);
}
.community p{
    font-size: 14px;
}
.community p a{
    color: #c12688;
}
.community hr{
    border-color: #c126884b;
    margin-bottom: 50px;
}
</style>
