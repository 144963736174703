<template>
  <div class="talks animated fadeIn">
    <div class="body">
        <div class="row">
            <div class="col-md-6 col-6">
                <SidebarView title="Talks" single="true" />
            </div>
            <div class="col-md-6 col-6 text-right">
                <router-link to="/">
                    <img src="../assets/images/icon.jpg" class="myicon" />
                </router-link>
            </div>
        </div>
        <br /><br />
        <div class="accordion" id="accordionExample">
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwentyFour">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyFour" aria-expanded="false" aria-controls="collapseTwentyFour">
                    <span class="project-title">2024</span>
                </button>
                </h2>
                <div id="collapseTwentyFour" class="accordion-collapse collapse show" aria-labelledby="headingTwentyFour" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <p>
                            Building Modern Distributed Applications <br />
                            <strong>RenderAtl 2024</strong> • <em>June 12th - 14th, 2024 </em> • <a target="_blank" rel="noopener" href="https://www.renderatl.com/">See Event</a>
                        </p>
                        <p>
                            Implementing Infrastructure as Code for Efficient Platform Engineering <br />
                            <strong>PlatformCon 2024</strong> • <em>June 10th, 2024 </em> • <a target="_blank" rel="noopener" href="https://platformcon.com/">See Event</a>
                        </p>
                        <p>
                            Designing Secure Cloud Native Apps with .NET and Azure <br />
                            <strong>ABP Dotnet Conference 2024</strong> • <em>May 9th, 2024 </em> • <a target="_blank" rel="noopener" href="https://abp.io/conference/2024/speakers/adora-nwodo">See Event</a>
                        </p>
                        <p>
                            Securing Cloud Deployments using Policy as Code <br />
                            <strong>Women in Tech Global Conference 2024</strong> • <em>April 24th, 2024 </em> • <a target="_blank" rel="noopener" href="https://www.womentech.net/speaker/all/all/100406">See Event</a>
                        </p>
                        <p>
                            Borderless Cloud: Designing, Implementing, and Securing Apps Across Multiple Clouds <br />
                            <strong>QCon London 2024</strong> • <em>April 8th - 10th, 2024 </em> • <a target="_blank" rel="noopener" href="https://qconlondon.com/speakers/apr2024">See Event</a>
                        </p>
                        <p>
                            Observability for Resilience in Distributed Systems <br />
                            <strong>CityJS London 2024</strong> • <em> April 3rd - 5th, 2024 </em> • <a target="_blank" rel="noopener" href="https://london.cityjsconf.org/speaker/2TUGUrVBZwaPxOsbUsUeRA">See Event</a>
                        </p>
                        <p>
                            Fireside Chat : Serverless Computing: Simplifying Application Development And Deployment <br />
                            <strong>Internet2.0 Conference</strong> • <em> March 20th, 2024 </em> • <a target="_blank" rel="noopener" href="https://www.internet2conf.com/usa-2024">See Event</a>
                        </p>
                        <p>
                            Panel Discussion: Leveraging Technology For Environmental And Social Impact <br />
                            <strong>Internet2.0 Conference</strong> • <em> March 18th, 2024 </em> • <a target="_blank" rel="noopener" href="https://www.internet2conf.com/usa-2024">See Event</a>
                        </p>
                        <p>
                            Observability for Resilience in Distributed Systems <br />
                            <strong>Women on Stage IWD-2024 Conference</strong> • <em> March 14th, 2024 </em> • <a target="_blank" rel="noopener" href="https://www.womenonstage.net/iwd-2024">See Event</a>
                        </p>
                        <p>
                            Maintaining your LinkedIn Account as a Professional <br />
                            <strong>Deloitte Brand Week 2024</strong> • <em>February 20th, 2024 </em>
                        </p>
                        <p>
                            Leveraging Policy as Code for Robust DevSecOps <br />
                            <strong>Automation Week 2024</strong> • <em>January 25th - 26th, 2024 </em> • <a target="_blank" rel="noopener" href="https://www.hiphops.io/automation-week">See Event</a>
                        </p>
                        <p>
                            Tech Meets Law (Panel Session) <br />
                            <strong>Future Fest Pakistan</strong> • <em>January 26th - 28th, 2024 </em> • <a target="_blank" rel="noopener" href="https://futurefest.pk/speakers/">See Event</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwentyThree">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyThree" aria-expanded="true" aria-controls="collapseTwentyThree">
                    <span class="project-title">2023</span>
                </button>
                </h2>
                <div id="collapseTwentyThree" class="accordion-collapse collapse" aria-labelledby="headingTwentyThree" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <p>
                            DevFest 2023<br />
                            <strong>Pulumi Adventures: How Python Empowered My Infrastructure Beyond YAML</strong> • <em>November 25th, 2023</em>  • <a target="_blank" rel="noopener" href="https://www.devfestlagos.com/speakers">See Event</a>
                        </p>
                        <p>
                            CityJS Conf Lagos<br />
                            <strong>Fullstack Typescript - Application, Infrastructure, and Security</strong> • <em>November 11th, 2023</em>  • <a target="_blank" rel="noopener" href="https://www.youtube.com/watch?v=Urn19XYap3k">See Event</a>
                        </p>
                        <p>
                            Localhost Conf 2023<br />
                            <strong>Python-Powered Infrastructure as Code</strong> • <em>October 21st, 2023</em>  • <a target="_blank" rel="noopener" href="https://sessionize.com/localhost-conference-2023/">See Event</a>
                        </p>
                        <p>
                            Global Devslam (Colocated with GITEX Global)<br />
                            <strong>Infrastructure Automation with Pulumi</strong> • <em>October 16th - 20th, 2023</em>  • <a target="_blank" rel="noopener" href="https://globaldevslam.com/">See Event</a>
                        </p>
                        <p>
                            QCon<br />
                            <strong>Pulumi Adventures: How Python Empowered My Infrastructure Beyond YAML</strong> • <em>October 1st - 4th, 2023</em>  • <a target="_blank" rel="noopener" href="https://qconsf.com/speakers/oct2023">See Event</a>
                        </p>
                        <p>
                            Securing Cloud Deployment using Pulumi's Infrastructure Policy as Code<br />
                            <strong>SysConf by SysDesign</strong> • <em>September 16th, 2023</em>  • <a target="_blank" rel="noopener" href="https://hopin.com/events/sysconf/registration">See Event</a>
                        </p>
                        <p>
                            Integrating Security into the CI/CD pipeline<br />
                            <strong>EC-Council #CyberTalks</strong> • <em>September 7th, 2023</em>  • <a target="_blank" rel="noopener" href="https://www.linkedin.com/posts/ec-council_eccouncil-webinar-devsecops-activity-7104840958013243393-9D9M?utm_source=share&utm_medium=member_desktop">See Event</a>
                        </p>
                        <p>
                            A Fireside Chat<br />
                            <strong>Africa Fintech Foundry - Women in Tech 3.0</strong> • <em>August 26th, 2023</em>  • <a target="_blank" rel="noopener" href="https://www.instagram.com/p/CwTQ5alsgd6/">See Event</a>
                        </p>
                        <p>
                            The Power of Software in Space Exploration: Inspiring Future Space Careers<br />
                            <strong>Tanzania Space Education Summit 2023</strong> • <em>August 24th - 25th, 2023</em>  • <a target="_blank" rel="noopener" href="https://twitter.com/adoranwodo/status/1694697822208041276">See Event</a>
                        </p>
                        <p>
                            Panel Session: Where Technology Meets Business<br />
                            <strong>Lagos Business School Webinar</strong> • <em>July 29th, 2023</em>  • <a target="_blank" rel="noopener" href="https://twitter.com/adoranwodo/status/1685309474485968897">See Event</a>
                        </p>
                        <p>
                            Panel Session: Where Technology Meets Business<br />
                            <strong>Lagos Business School Webinar</strong> • <em>July 29th, 2023</em>  • <a target="_blank" rel="noopener" href="https://twitter.com/adoranwodo/status/1685309474485968897">See Event</a>
                        </p>
                        <p>
                            The Impact of Technology on the Creative Process and the Future of Creativity<br />
                            <strong>Selar Digital Creator Summit</strong> • <em>July 6th, 2023</em>  • <a target="_blank" rel="noopener" href="https://www.instagram.com/p/CtJeg_0MvtX/?img_index=4">See Event</a>
                        </p>
                        <p>
                            The Impact of Technology on the Creative Process and the Future of Creativity<br />
                            <strong>Selar Digital Creator Summit</strong> • <em>July 6th, 2023</em>  • <a target="_blank" rel="noopener" href="https://www.instagram.com/p/CtJeg_0MvtX/?img_index=4">See Event</a>
                        </p>
                        <p>
                            Guest Speech<br />
                            <strong>Genesys Tech Hub - Learnable Virtual Day</strong> • <em>June 26th, 2023</em>  • <a target="_blank" rel="noopener" href="https://www.instagram.com/p/Ct7HjgZMr6O/?igshid=MzRlODBiNWFlZA%3D%3D">See Event</a>
                        </p>
                        <p>
                            The Role of Artificial Intelligence in Customer Communication: Automation, Personalization, and Beyond<br />
                            <strong>Termii Elevate Conference 2.0</strong> • <em>June 24th, 2023</em>  • <a target="_blank" rel="noopener" href="https://twitter.com/termii_network/status/1672549139517693952">See Event</a>
                        </p>
                        <p>
                            Harnessing the Power of AI in Cloud Engineering<br />
                            <strong>Open Source Community Africa Festival (OSCAFEST) 2023</strong> • <em>June 15th - 17th, 2023</em>  • <a target="_blank" rel="noopener" href="https://oscafest23.sched.com/">See Event</a>
                        </p>
                        <p>
                            Panel Session<br />
                            <strong>HerVest #ValueUp</strong> • <em>May 20th, 2023</em>  • <a target="_blank" rel="noopener" href="https://www.instagram.com/p/CsWqQH8MkXc/?img_index=6">See Event</a>
                        </p>
                        <p>
                            Building Digital Skills for Life<br />
                            <strong>Stanbic IBTC #WomenInTechWebinar</strong> • <em>May 6th, 2023</em>  • <a target="_blank" rel="noopener" href="https://twitter.com/StanbicIBTC/status/1654454843103117312">See Event</a>
                        </p>
                        <p>
                            Empowering Girls Through Technology<br />
                            <strong>Junior Achievement Nigeria: TechUp4Girls</strong> • <em>May 4th, 2023</em>  • <a target="_blank" rel="noopener" href="https://www.linkedin.com/posts/janigeria_techup4girls-vimpalum-lhgp-activity-7059473447126450176-Emwu">See Event</a>
                        </p>
                        <p>
                            How I Got Into Tech<br />
                            <strong>Babcock University Computer Club: Career Fair</strong> • <em>May 3rd, 2023</em>  • <a target="_blank" rel="noopener" href="https://twitter.com/bucc__official/status/1651907081383948289">See Event</a>
                        </p>
                        <p>
                            Keynote Speech: You Belong In Tech<br />
                            <strong>University of Ibadan GDSC #IWD2023 Event</strong> • <em>April 15th, 2023</em>  • <a target="_blank" rel="noopener" href="https://twitter.com/gdsc_ui/status/1646863941509738496">See Event</a>
                        </p>
                        <p>
                            Emerging Technologies And Their Impact In Business<br />
                            <strong>Unilag MCSA Event</strong> • <em>April 11th, 2023</em>
                        </p>
                        <p>
                            IWD Presentation<br />
                            <strong>Special Maths Academy Career Event</strong> • <em>April 8th, 2023</em>
                        </p>
                        <p>
                            Relevance Of Mathematics And Statistics To Tech<br />
                            <strong>UNILAG (NAMSSN) Symposium</strong> • <em>April 3rd, 2023</em>
                        </p>
                        <p>
                            Resume Reboot: Crafting Your Career Story As A Techie<br />
                            <strong>WeTech #IWD2023 #WTSession23</strong> • <em>March 25th, 2023</em>  • <a target="_blank" rel="noopener" href="https://twitter.com/wetechofficial/status/1639613658975162368">See Event</a>
                        </p>
                        <p>
                            Panel Session<br />
                            <strong>SheCodeAfrica Lagos Bonding Session</strong> • <em>March 25th, 2023</em>
                        </p>
                        <p>
                            Getting into the Tech Space: The When and How<br />
                            <strong>TEDxFUTO</strong> • <em>March 22nd, 2023</em>
                        </p>
                        <p>
                            Emerging Tech Trends and Careers in Cloud Computing<br />
                            <strong>NVIDIA GTC23</strong> • <em>March 21st, 2023</em>  • <a target="_blank" rel="noopener" href="https://www.nvidia.com/gtc/session-catalog/?tab.catalogallsessionstab=16566177511100015Kus&search=SE52627#/session/1677014737966001EPST&ncid=ref-inpa-131950">See Event</a>
                        </p>
                        <p>
                            Leading the Digital Revolution<br />
                            <strong>The PowerWoman Network #IWD23 Virtual Conference</strong> • <em>March 8th, 2023</em>  • <a target="_blank" rel="noopener" href="https://www.linkedin.com/feed/update/urn:li:activity:7038866270829649921?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7038866270829649921%29">See Event</a>
                        </p>
                        <p>
                            Creating Opportunties For Women In Tech<br />
                            <strong>Creath #IWD23 Women In Web3 Virtual Conference</strong> • <em>March 8th, 2023</em>  • <a target="_blank" rel="noopener" href="https://www.linkedin.com/feed/update/urn:li:activity:7041724936423387137?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7041724936423387137%29">See Event</a>
                        </p>
                        <p>
                            International Women's Day: Fireside Chat<br />
                            <strong>International Breweries #IWD23</strong> • <em>March 8th, 2023</em>  • <a target="_blank" rel="noopener" href="https://www.linkedin.com/feed/update/urn:li:activity:7038471191497433089?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7038471191497433089%29">See Event</a>
                        </p>
                        <p>
                            Designing Secure Applications in the Cloud<br />
                            <strong>Developer Week (Cloud Connect)</strong> • <em>February 22nd, 2023</em>  • <a target="_blank" rel="noopener" href="https://sched.co/1GNzE">See Event</a>
                        </p>
                        <p>
                            Insights and Opportunities for Young Professionals<br />
                            <strong>Eko Innovation Center Webinar</strong> • <em>February 18th, 2023</em> 
                        </p>
                        <p>
                            Panel Session<br />
                            <strong>Tech4Dev - Women Techsters Open Day</strong> • <em>January 26th, 2023</em> 
                        </p>
                        <p>
                            Panel Session<br />
                            <strong>Educative Answers Community Meetup</strong> • <em>January 25th, 2023</em> 
                        </p>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <span class="project-title">2022</span>
                </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <p>
                            Infrastructure Automation with TypeScript<br />
                            <strong>CityJS Lagos Conference</strong> • <em>October 22nd, 2022</em> • <a target="_blank" rel="noopener" href="https://nigeria.cityjsconf.org/">See Website</a>
                        </p>
                        <p>
                            Women In Tech (Panel Session)<br />
                            <strong>ImpactHER's International Women's Day</strong> • <em>March 9th, 2022</em> 
                        </p>
                        <p>
                            Women In Tech (Panel Session)<br />
                            <strong>Stride ERP Women In Tech Webinar</strong> • <em>March 5th, 2022</em> 
                        </p>
                        <p>
                            Tech Career Pathways<br />
                            <strong>YaaW Launch your Career Conference 2022</strong> • <em>February 25th, 2022</em> • <a target="_blank" rel="noopener" href="https://linktr.ee/accomplishedafricanwomen">See Website</a>
                        </p>
                        <p>
                            State of Web Performance (Panel Session)<br />
                            <strong>This Dot Media - State of the Web</strong> • <em>February 22nd, 2022</em> • <a target="_blank" rel="noopener" href="https://www.thisdotmedia.com/state-of-the-web/#/">See Website</a>
                        </p>
                        <p>
                            Women in Tech (Roundtable Session)<br />
                            <strong>Global Power Platform Bootcamp, Lagos</strong> • <em>February 19th, 2022</em> • <a target="_blank" rel="noopener" href="https://www.eventbrite.com/e/global-power-platform-bootcamp-2022-lagos-nigeria-tickets-224808497267">See Website</a>
                        </p>
                        <p>
                            Leveraging Digital Skills And Tools To Win In The Creator Economy<br />
                            <strong>Digital Creators Summit 2022</strong> • <em>February 18th, 2022</em> • <a target="_blank" rel="noopener" href="https://summit.selar.co/">See Website</a>
                        </p>
                        <p>
                            Getting started with Cloud Engineering<br />
                            <strong>"Hack Your Tech Career" Summit</strong> • <em>February 16th - 17th, 2022</em> • <a target="_blank" rel="noopener" href="https://techmindssummit.com/">See Website</a>
                        </p>
                        <p>
                            Positioning Yourself For Hybrid Work/Remote Jobs<br />
                            <strong>Enyata Webinar</strong> • <em>February 11th, 2022</em> • <a target="_blank" rel="noopener" href="https://enyata.com/">See Website</a>
                        </p>
                        <p>
                            AMA with Adora Nwodo<br />
                            <strong>Dev Career Webinar</strong> • <em>February 9th, 2022</em> • <a target="_blank" rel="noopener" href="https://devcareer.io/">See Website</a>
                        </p>
                        <p>
                            Typescript for Infrastructure Engineering<br />
                            <strong>JS World Conference Africa</strong> • <em>February 8th, 2022</em> • <a target="_blank" rel="noopener" href="https://africa.jsworldconference.com/">See Website</a>
                        </p>
                        <p>
                            Making A Career Switch to Tech<br />
                            <strong>Bincom Dev Center Community Event</strong> • <em>January 29th, 2022</em> • <a target="_blank" rel="noopener" href="https://www.bincom.net/academy/">See Website</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <span class="project-title">2021</span>
                </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <p>
                            Building Accessible Mixed Reality<br />
                            <strong>DevCon Live 2021</strong> • <em>November 4th, 2021</em> • <a target="_blank" rel="noopener" href="https://www.dev-con.ro/">See Event</a>
                        </p>
                        <p>
                            Software Engineering in Infrastructure Engineering<br />
                            <strong>Pulumi Cloud Engineering Summit</strong> • <em>October 20th, 2021</em> • <a target="_blank" rel="noopener" href="https://www.pulumi.com/cloud-engineering-summit/">See Event</a>
                        </p>
                        <p>
                            Durable Functions in .NET<br />
                            <strong>MK.NET MeetUp</strong> • <em>October 19th, 2021</em>
                        </p>
                        <p>
                            Building Products Through The Pandemic<br />
                            <strong>WeTech (Women Builders in Tech)</strong> • <em>September 25th, 2021</em> • <a target="_blank" rel="noopener" href="https://wetechng.com">See Event</a>
                        </p>
                        <p>
                            Fusing Extended Reality and Blockchain - The impact to future technology (Panel Session)<br />
                            <strong>WBS Black in Business event</strong> • <em>September 23rd, 2021</em>
                        </p>
                        <p>
                            Getting started and working in Tech<br />
                            <strong>SheCodeAfrica Summer Code Camp</strong> • <em>August 14th, 2021</em>
                        </p>
                        <p>
                            Fireside Chat with Sofware Engineers at Microsoft<br />
                            <strong>Evolve Circle</strong> • <em>July 30th, 2021</em>
                        </p>
                        <p>
                            Designing Secure Applications in the Cloud<br />
                            <strong>Cyber Security Global Summit 2021</strong> • <em>July 27th, 2021</em> • <a target="_blank" rel="noopener" href="https://cs.geekle.us/">See Event</a>
                        </p>
                        <p>
                            Augmented & Virtual Reality - The Transformed Entertainment Era<br />
                            <strong>Digital Africa 2021</strong> • <em>June 24th, 2021</em> • <a target="_blank" rel="noopener" href="https://www.digitalafrica.com.ng/">See Event</a>
                        </p>
                        <p>
                            7 Habits of Highly Effective Engineering Teams<br />
                            <strong>PagerDuty Summit</strong> • <em>June 22nd - 25th, 2021</em> • <a target="_blank" rel="noopener" href="https://summit.pagerduty.com/">See Event</a>
                        </p>
                        <p>
                            Panel Session<br />
                            <strong>Shehacks Summit</strong> • <em>June 17th, 2021</em> • <a target="_blank" rel="noopener" href="https://shehacks.ng/">See Event</a>
                        </p>
                        <p>
                            Creating An Attractive Software Developer Portfolio<br />
                            <strong>FemCode Africa's FemBoot v3.0</strong> • <em>June 6th, 2021</em>
                        </p>
                        <p>
                            Ladies Talk Tech<br />
                            <strong>Tech in Heels Virtual Confab</strong> • <em>May 29th, 2021</em>
                        </p>
                        <p>
                            Designing Secure Applications in the Cloud<br />
                            <strong>EC-Council University Cyber Talks 2021</strong> • <em>May 11th, 2021</em> • <a target="_blank" rel="noopener" href="https://www.eccu.edu/how-to-design-secure-applications-in-the-cloud/">See Event</a>
                        </p>
                        <p>
                            Designing Secure Applications in the Cloud<br />
                            <strong>HUE Tech Summit 2021</strong> • <em>May 7th, 2021</em> • <a target="_blank" rel="noopener" href="https://huetechsummit.com/">See Event</a>
                        </p>
                        <p>
                            7 Habits of Highly Effective Engineering Teams<br />
                            <strong>Geekle's React JS Case Study Festival</strong> • <em>April 27th - 28th, 2021</em> • <a target="_blank" rel="noopener" href="https://react.geekle.us/">See Event</a>
                        </p>
                        <p>
                            What Constitutes Clean and/or Good Code (Panel Discussion) <br />
                            <strong>OpenMR Benelux conference 2021</strong> • <em>March 24th, 2021</em> • <a target="_blank" rel="noopener" href="https://openmrbenelux.github.io/">See Event</a>
                        </p>
                        <p>
                            The Journey to a Million Lines of Code<br />
                            <strong>STEMi Africa IWD2021</strong> • <em>March 20th, 2021</em> • <a target="_blank" rel="noopener" href="https://stemiafrica.org/">Visit Website</a>
                        </p>
                        <p>
                            The Future of Mixed Reality<br />
                            <strong>YouTube live (with Lee Robinson)</strong> • <em>March 19th, 2021</em> • <a target="_blank" rel="noopener" href="https://www.youtube.com/watch?v=uGMPt39vkvs">Watch Video</a>
                        </p>
                        <p>
                            IWD Panel<br />
                            <strong>SheDesigns Africa</strong> • <em>March 19th, 2021</em> • <a target="_blank" rel="noopener" href="https://shedesigns.africa/">Visit Website</a>
                        </p>
                        <p>
                            The Journey to a Million Lines of Code<br />
                            <strong>Microsoft Learn Student Ambassadors Nigeria IWD Event 2021 -  Being a Woman in tech</strong> • <em>March 13th, 2021</em>
                        </p>
                        <p>
                            Designing Secure Applications in the Cloud<br />
                            <strong>TECH(K)NOW Day 2021</strong> • <em>March 8th, 2021</em> • <a target="_blank" rel="noopener" href="https://www.techknowday.com/">See Event</a> • <a target="_blank" rel="noopener" href="https://www.youtube.com/watch?v=fUrY2nVPmJc">Watch Video</a>
                        </p>
                        <p>
                            The Journey to a Million Lines of Code<br />
                            <strong>The Live Coders International Women's Day Challenge for Women 2021</strong> • <em>March 8th, 2021</em> • <a target="_blank" rel="noopener" href="https://livecoders.dev/">See Event</a>
                        </p>
                        <p>
                            The Future of Tech panel<br />
                            <strong>Baddies in Tech Launch Event</strong> • <em>February 26th, 2021</em> • <a target="_blank" rel="noopener" href="https://baddiesintech.co/baddie-tech-over-black-women-the-future-of-tech/">See Event</a> • <a target="_blank" rel="noopener" href="https://vimeo.com/516489345/10aeff8132">Watch Video</a>
                        </p>
                        <p>
                            Accessibility in the context of Mixed Reality<br />
                            <strong>ConFoo Online 2021</strong> • <em>February 24th - 26th, 2021</em> • <a target="_blank" rel="noopener" href="https://confoo.ca/fr/yul2021/session/accessibility-in-the-context-of-mixed-reality">See Event</a> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/accessibility-in-the-context-of-mixed-reality">See Presentation</a>
                        </p>
                        <p>
                            Stereokit: The Open Source SDK for VR/MR<br />
                            <strong>ConFoo Online 2021</strong> • <em>February 24th - 26th, 2021</em> • <a target="_blank" rel="noopener" href="https://confoo.ca/fr/yul2021/session/stereokit-the-open-source-sdk-for-vr-mr">See Event</a> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/mr">See Presentation</a>
                        </p>
                        <p>
                            Designing Secure Applications in the Cloud<br />
                            <strong>Postman Galaxy v2</strong> • <em>February 2nd - 4th, 2021</em> • <a target="_blank" rel="noopener" href="https://hopin.to/events/galaxy">See Event</a> • <a target="_blank" rel="noopener" href="https://www.postman.com/postman-galaxy/designing-secure-apis-in-the-cloud/">Watch Video</a> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/designing-secure-cloud-applications">See Presentation</a>
                        </p>
                        <p>
                            Skills to Excel in SRE & Cloud Engineering<br />
                            <strong>SheCodeAfrica Cloud Engineering Bootcamp Awareness Event</strong> • <em>January 10th, 2021</em> • <a target="_blank" rel="noopener" href="https://www.shecodeafrica.org/events">View Event</a> • <a target="_blank" rel="noopener" href="https://www.youtube.com/watch?v=edHX0FRta4g">Watch Video</a> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/skills-to-excel-in-cloud-engineering">See presentation</a>
                        </p>
                        <p>
                            Building a Sustainable Personal Brand<br />
                            <strong>Developers in Vogue, Meet The Boss Session</strong> • <em>January 10th, 2021</em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/building-a-sustainable-personal-brand">See Presentation</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <span class="project-title">2020</span>
                </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <p>
                            The Future of Nigeria's Gig Economy<br />
                            <strong>UK-Nigeria Tech Hubs, Fireside chat</strong> • <em>December 15th, 2020</em> • <a target="_blank" rel="noopener" href="https://twitter.com/Wootlabng/status/1338791175940149248">See post</a>
                        </p>
                        <p>
                            The Opportunities in the Decade of Mixed Realities<br />
                            <strong>DeveloperWeek New York 2020</strong> • <em>December 10th, 2020</em> • <a target="_blank" rel="noopener" href="https://www.developerweek.com/NYC/conference/speakers/">Visit Website</a> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/the-opportunities-in-the-decade-of-mixed-realities">See Presentation</a>
                        </p>
                        <p>
                            Building AR Experiences on Android with Azure Spatial Anchors<br />
                            <strong>Droidcon Italy 2020</strong> • <em>November 27th - 28th, 2020</em> • <a target="_blank" rel="noopener" href="https://it.droidcon.com/2020/">Visit Website</a> • <a target="_blank" rel="noopener" href="https://www.talque.com/app#/app/org/org-event/lUKo5OgmDyzARlWsBTSE/6Bfr8Vn3MlaqwifgoCkS/info">Watch Video</a>
                        </p>
                        <p>
                            If you can imagine it, you can build it with XR<br />
                            <strong>ICT4Girls Virtual Community Day</strong> • <em>November 20th, 2020</em> • <a target="_blank" rel="noopener" href="https://www.eko-konnect.org.ng/time-to-level-up-your-skills-register-for-the-ict4girls-program/">Visit Website</a> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/you-can-build-it-with-xr">See Presentation</a> • <a target="_blank" rel="noopener" href="https://www.youtube.com/watch?v=RVEThLWvD98">Watch Video</a>
                        </p>
                        <p>
                            7 Habits of Highly Effective Engineering Teams<br />
                            <strong>Live Coders Conf 3</strong> • <em>November 19th, 2020</em> • <a target="_blank" rel="noopener" href="https://livecoders.dev/lcc3/#Adora%20Nwodo">Visit Website</a> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/7-habits-of-highly-effective-engineering-teams">See Presentation</a>
                        </p>
                        <p>
                            Designing Secure Applications in the Cloud<br />
                            <strong>Linux Foundation - Cloud Native Security Day North America 2020</strong> • <em>November 17th, 2020 </em> • <a target="_blank" rel="noopener" href="https://events.linuxfoundation.org/cloud-native-security-day-north-america/">Visit Website</a> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/designing-secure-cloud-applications">See Presentation</a>
                        </p>
                        <p>
                            Dotnet for Infrastructure Automation<br />
                            <strong>.NET Conf 2020</strong> • <em>November 10th - 12th, 2020</em> • <a target="_blank" rel="noopener" href="https://www.dotnetconf.net/">Visit Website</a> • <a target="_blank" rel="noopener" href="https://www.youtube.com/watch?v=nAlnIvi1m4E">Watch Video</a>
                        </p>
                        <p>
                            Moderator<br />
                            <strong>The unStack Conference</strong> • <em>November 6th - 7th, 2020 </em> • <a target="_blank" rel="noopener" href="https://unstack.africa/">Visit Website</a>
                        </p>
                        <p>
                            Economic Benefits of Mixed Reality in Africa<br />
                            <strong>ARVR Hackathon: Workshop Session</strong> • <em>November 3rd, 2020 </em> • <a target="_blank" rel="noopener" href="https://arvrafrica.com/">Visit Website</a>
                        </p>
                        <p>
                            Designing Secure Applications in the Cloud<br />
                            <strong>API World 2020</strong> • <em>October 28th, 2020 </em> • <a target="_blank" rel="noopener" href="https://apiworld.co/conference/speakers/">Visit Website</a> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/designing-secure-cloud-applications">See Presentation</a>
                        </p>
                        <p>
                            Managing Your Cloud Application and Infrastructure Deployments in One CI/CD Pipeline<br />
                            <strong>Pulumi Cloud Engineering Summit 2020</strong> • <em>October 8th, 2020</em> • <a target="_blank" rel="noopener" href="https://cloudengineering.heysummit.com/speakers/nenne-adaora-adora-nwodo/">Visit Website</a> • <a target="_blank" rel="noopener" href="https://cloudengineering.heysummit.com/talks/managing-your-cloud-application-and-infrastructure-deployments-in-one-cicd-pipeline/">Replay talk</a>
                        </p>
                        <p>
                            Designing secure cloud applications<br />
                            <strong>DeveloperWeek Global Cloud 2020</strong> • <em>September 30th, 2020</em> • <a target="_blank" rel="noopener" href="https://www.developerweek.com/global/conference/cloud/speakers/">Visit Website</a> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/designing-secure-cloud-applications">See Presentation</a>
                        </p>
                        <p>
                            Opportunities for Mixed Reality in Africa<br />
                            <strong>PUNUKA Nominees - Accelerating Digital Adoption: Discovering the Use of VR to Improve Business</strong> • <em>September 30th, 2020</em> • <a target="_blank" rel="noopener" href="https://www.linkedin.com/feed/update/urn:li:activity:6714168238617440256/">Event link</a> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/opportunities-for-mixed-reality-in-africa">See Presentation</a>
                        </p>
                        <p>
                            Ask me anything about Azure Spatial Anchors<br />
                            <strong>AMA with MLSA Nigeria XR Community</strong> • <em>September 29th, 2020</em> • <a target="_blank" rel="noopener" href="https://twitter.com/mlsanigeria/status/1305934803917844481">Event link</a>
                        </p>
                        <p>
                            Building a sustainable personal brand<br />
                            <strong>KwaraBuild 2020</strong> • <em>August 20th, 2020</em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/building-a-sustainable-personal-brand">See Presentation</a> • <a target="_blank" rel="noopener" href="https://www.youtube.com/watch?v=221VNcIZyGU">Watch Video</a>
                        </p>
                        <p>
                            If you can imagine it, then you can build it with XR<br />
                            <strong>Mixed Reality - Rise of Titans, NACOSS Oduduwa University Chapter</strong> • <em>August 17th, 2020</em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/you-can-build-it-with-xr">See Presentation</a>
                        </p>
                        <p>
                            Beyond the code. The journey to becoming a complete developer<br />
                            <strong>Ruby virtual meetup, Ruby Conf Nigeria</strong> • <em>August 8th, 2020</em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/the-journey-to-becoming-a-complete-developer">See Presentation</a>
                        </p>
                        <p>
                            Crawl, Walk, Run, Fly. Go From Newbie To Experienced Developer<br />
                            <strong>IWD Celebration, Women Techmakers Lagos</strong> • <em>June 27th, 2020</em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/crawl-walk-run-fly-go-from-newbie-to-experienced-developer">See Presentation</a>
                        </p>
                        <p>
                            Attracting Recruiters on LinkedIn and bagging the best Tech jobs<br />
                            <strong>Women In Tech Spotlight Saturday at Femcode Africa</strong> • <em>June 13th, 2020</em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/attracting-recruiters-on-linkedin">See Presentation</a>
                        </p>
                        <p>
                            An Introduction to Mixed Reality Design<br />
                            <strong>MSPs Virtual Design Exhibition</strong> • <em>May 14th, 2020</em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/introduction-to-mixed-reality-design">See Presentation</a>
                        </p>
                        <p>
                            Social Distancing and Technology<br />
                            <strong>Dev Around The Sun</strong> • <em>May 12th, 2020</em> • <a target="_blank" rel="noopener" href="https://devaroundthesun.org/speakers">Visit Website</a> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/social-distancing-and-technology">See Presentation</a> • <a target="_blank" rel="noopener" href="https://www.twitch.tv/videos/618660091">Watch Video</a>
                        </p>
                        <p>
                            Software Development Tour Guide<br />
                            <strong>Techplus Masterclass</strong> • <em>April 14th, 2020</em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/software-development-tour-guide">See Presentation</a>
                        </p>
                        <p>
                            Crawl, Walk, Run, Fly. Go From Newbie To Experienced Developer<br />
                            <strong>IWD Celebration, Women of DevC Lokoja</strong> • <em>March 28th, 2020</em> • <a target="_blank" rel="noopener" href="https://iwddevclokoja2020.splashthat.com/">Visit Website</a> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/crawl-walk-run-fly-go-from-newbie-to-experienced-developer">See Presentation</a>
                        </p>
                        <p>
                            Delighting your customers and improving their experiences using AR/VR (Panel Session)<br />
                            <strong>Social Media Week Lagos 2020</strong> • <em>February 25th, 2020</em> • <a target="_blank" rel="noopener" href="https://socialmediaweeklagos2020.sched.com/event/Zt0j/">Visit Website</a>
                        </p>
                        <p>
                            Opportunities for Mixed Reality in Africa<br />
                            <strong>Open Source Africa Festival 2020</strong> • <em>February 20th - 22nd, 2020</em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/opportunities-for-mixed-reality-in-africa">See Presentation</a>
                        </p>
                        <p>
                            An Introduction to Mixed Reality<br />
                            <strong>AR/VR Africa Community Meetup</strong> • <em>January 25th, 2020</em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/an-introduction-to-mixed-reality">See Presentation</a>
                        </p>
                        <p>
                            Tech Career Journies (Panel Session)<br />
                            <strong>Women Who Code Lagos - Career Series 1</strong> • <em>January 18th, 2020</em> • <a target="_blank" rel="noopener" href="https://www.womenwhocode.com/lagos">Visit Website</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    <span class="project-title">2019</span>
                </button>
                </h2>
                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <p>
                            The state of Women in Tech in Africa and how we can do better.<br />
                            <strong>Microsoft Women in Cognition Summit, San Francisco</strong> • <em>December 6th, 2019 </em>
                        </p>
                        <p>
                            Be good to yourself. Write testable code.<br />
                            <strong>DevFest Ajah 2019</strong> • <em>November 16th, 2019 </em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/be-good-to-yourself-write-testable-code">See Presentation</a>
                        </p>
                        <p>
                            Women owning our voices (Panel session)<br />
                            <strong>DevFest Lagos 2019</strong> • <em>November 2nd, 2019 </em>
                        </p>
                        <p>
                            Productive Debugging. Because time is money.<br />
                            <strong>Concatenate Conference Lagos</strong> • <em>October 11th, 2019 </em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/productive-debugging-because-time-is-money">See Presentation</a> • <a target="_blank" rel="noopener" href="https://www.youtube.com/watch?v=NzGCI_uGm0o">Watch Video</a>
                        </p>
                        <p>
                            Kotlin for Beginners<br />
                            <strong>Kotlin/Everywhere Lagos</strong> • <em>August 10th, 2019 </em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/kotlin-for-beginners">See Presentation</a>
                        </p>
                        <p>
                            The MVI Architecture<br />
                            <strong>Developing Android Developers 3.0</strong> • <em>July 27th, 2019 </em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/the-mvi-architecture-a-reactive-approach-to-android-development">See Presentation</a>
                        </p>
                        <p>
                            Kotlin with JavaScript & React: A Reality<br />
                            <strong>Kotlin/Everywhere Ajah</strong> • <em>July 6th, 2019 </em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/kotlin-with-javascript-and-react-a-reality">See Presentation</a>
                        </p>
                        <p>
                            Using Kotlin Coroutines on Android (remote talk)<br />
                            <strong>Google I/O Extended Ikorodu</strong> • <em>June 8th, 2019 </em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/using-kotlin-coroutines-on-android">See Presentation</a>
                        </p>
                        <p>
                            Using Kotlin Coroutines on Android<br />
                            <strong>Google I/O Extended Ajah</strong> • <em>June 8th, 2019 </em> • 
                            <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/using-kotlin-coroutines-on-android">See Presentation</a> • <a target="_blank" rel="noopener" href="https://www.youtube.com/watch?v=bGLDG-60AG8">Watch Video</a>
                        </p>
                        <p>
                            Progressive Web AMPs for the mobile web<br />
                            <strong>Full Stack Developers Lagos Meetup</strong> • <em>May 4th, 2019 </em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/pwamps-for-the-mobile-web">See Presentation</a>
                        </p>
                        <p>
                            Zero to Android Developer: Important things to get you started<br />
                            <strong>Women Techmakers Ajah. IWD2019</strong> • <em>April 27th, 2019 </em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/zero-to-android-developer">See Presentation</a>
                        </p>
                        <p>
                            Building compelling Android apps with Firebase <br />
                            <strong>GDG Ajah Cloud Study Jam</strong> • <em>April 6th, 2019 </em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/building-compelling-android-apps-with-firebase">See Presentation</a> • <a target="_blank" rel="noopener" href="https://github.com/AdoraNwodo/bookofquotes">See Full Code</a>
                        </p>
                        <p>
                            Let's build a Vuejs web app (codelab) <br />
                            <strong>Vue Vixens 2019</strong> • <em>March 23rd, 2019 </em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/lets-build-a-vuejs-web-app">See Presentation</a> • <a target="_blank" rel="noopener" href="https://github.com/AdoraNwodo/vue-vixens-petshop">See Full Code</a>
                        </p>
                        <p>
                            Tech as a lifestyle and how it fits you <br />
                            <strong>Forloop Babcock 2019</strong> • <em>March 6th, 2019 </em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/tech-as-a-lifestyle-and-how-it-fits-you">See Presentation</a>
                        </p>
                        <p>
                            The power of consistency as a developer <br />
                            <strong>GDG Ojo-Festac #BATE2019</strong> • <em>March 2nd, 2019 </em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/consistency-as-a-developer">See Presentation</a>
                        </p>
                    </div>
                </div>
            </div>
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingFive">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    <span class="project-title">2018</span>
                </button>
                </h2>
                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <p>
                            Modern Android Development with Kotlin  <br />
                            <strong>GDG DevFest Ikorodu 2018</strong> • <em>December 8th, 2018 </em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/modern-android-development-with-kotlin-6abd608e-06da-494d-b1aa-db7212efc00e">See Presentation</a>
                        </p>
                        <p>
                            Test Driven Development for Android Applications <br />
                            <strong>Codify 2.0 Meetup</strong> • <em>November 30th, 2018 </em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/test-driven-development-for-android-applications">See Presentation</a>
                        </p>
                        <p>
                            Today in Mobile: Building with Kotlin & Flutter <br />
                            <strong>GDG DevFest Ajah 2018</strong> • <em>November 17th, 2018 </em> • <a target="_blank" rel="noopener" href="https://speakerdeck.com/adoranwodo/today-in-mobile-building-with-kotlin-and-flutter">See Presentation</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FooterView />
  </div>
</template>

<script>
import SidebarView from '@/components/SidebarView.vue' 
import FooterView from '@/components/FooterView.vue' 

export default {
    components: { 
        SidebarView,
        FooterView
    }
}
</script>

<style scoped>
@media only screen and (max-width: 770px) {
    .talks .talk-year{
        font-size: 15px !important;
    }
}
.talks .card{
    border-color: transparent !important;
}
.talks .btn-info{ 
    background: white !important;
    border-color: white !important;
    color: black;
    box-shadow: none !important;
    outline: none !important;
}
.talks .card-header{
    background: white !important;
}
.talks .talk-body{
    padding: 10px 20px;
}
.talks .talk-year{
    font-size: 20px;
}
.talks .accordion-body p, .talks .accordion-body a{
    font-size: 13px !important;
}
.talks p a{
    text-decoration: underline;
    color: black;
}
.talks p a:hover{
    color: #c12688;
}
.talks .accordion{
    border-radius: 0px !important;
}
.talks .accordion-item{
    border: none !important;
    border-bottom: 1px solid rgb(215, 215, 215) !important;
}
.talks .accordion-item a{
    color: black;
    font-size: 14px;
}
.talks .accordion img{
    width: 100% !important;
}
.talks .accordion-button{
    border-color: transparent;
}
.talks .accordion-button:focus{
    box-shadow: none;
}
.talks h2.accordion-header span{
    font-weight: bolder !important;
}
.accordion-button:not(.collapsed){
    box-shadow: none;
    background: white;
    color: black;
}
</style>
