<template>
  <div class="about animated fadeIn">
    <div class="body">
      <div class="row">
          <div class="col-md-6 col-6">
             <SidebarView title="About" single="true" />
          </div>
          <div class="col-md-6 col-6 text-right">
           <router-link to="/">
            <img src="../assets/images/icon.jpg" class="myicon"/>
           </router-link>
        </div>
      </div>
      <br /><br />
      <div class="row">
        <div class="col-md-5">
          <img src="../assets/images/timessquare.jpg" class="profileimage" />
        </div>
        <div class="col-md-7 description">
          <h1 class="hello">Hi, I'm Adora</h1>
          <br />
          <p>My name is Nenne Adaora Nwodo. I fondly like to be called Adora. I am a Software Engineer who loves the Cloud and Emerging Technologies.</p>
          <p>
            I currently work as a Senior Software Engineer where I work at the intersection of Cloud infrastructure and Developer Platforms.
            I have a Bachelor of Science (First class) in Computer Science from the University of Lagos, Nigeria. 
            I am also a LEAD alumni in the Stanford Graduate School of Business.
          </p>
          <p>
            I am also the Founder of <a href="https://nexascale.org" target="_blank" rel="noopener">NexaScale</a>, an initiative that is helping people start and scale their careers by connecting them to internships and job opportunities,
            as well as exposing them to real life projects that they can build to strengthen their portfolios.
          </p>
          <p>
            I have a blog and YouTube channel, where I post content on Software Engineering, Career Growth and Life as a Software Engineer. 
          </p>
          <p>
            In March 2021, I won the <a href="https://youngcisonetwork.com" target="_blank" rel="noopener">Young CISO Network</a> Excellence in Disruptive Technology, Cloud and Embedded
            Device Security Award for my work in building and advocating for Disruptive Technology on the Cloud. Since then, I've won global awards and I've been featured all around the world. See my awards <router-link to="/awards">here.</router-link>
          </p>
          <p>
            In October 2021, I published my first book titled Cloud Engineering for Beginners. <a href="https://adorahack.selar.co/cloud-engineering-book" target="_blank" rel="noopener">You can get the book here</a>.
          </p>
          <p>
            In April 2023, I published my second book titled Beginning Azure DevOps. <a href="https://www.amazon.com/Beginning-Azure-DevOps-Releasing-Applications/dp/1394165889" target="_blank" rel="noopener">You can get the book here</a>.
          </p>
          <p>
            My non-tech passion includes education & music. I believe everyone should be given a chance at basic education.
          </p>
          <p>
            In tech, I am a huge fan of the cloud and emerging technologies. At a tech event, you'd probably find me in a booth with a VR headset on, 
            or trying to play a game (and possibly failing at it).
          </p>
          <p>
            I am also passionate about the developer community and I am trying to drive inclusion for more women in tech. Because of this, 
            I am on the advisory board for <a href="https://www.thevrara.com/nigeria" target="_blank" rel="noopener">The VR/AR Association Nigeria</a> and <a href="https://www.devnetwork.com/about/advisory-boards/cloud-advisory-board/#members" target="_blank" rel="noopener">Dev Network</a>, 
            I speak at events all over the world & I mentor software developers.
          </p>
          <p>
            <a rel="noopener" target="_blank" href="https://twitter.com/adoranwodo">Say hi to me </a><br />
            <a href="https://www.buymeacoffee.com/adorahack" target="_blank" rel="noopener" style="color: #c12688;">Buy Me A Coffee!</a><br />
            <a href="https://www.patreon.com/adorahack" target="_blank" rel="noopener" style="color: #c12688;">Become a Patron!</a>
          </p>
        </div>
      </div>
      <img src="../assets/images/io.png" class="centerpattern"/>
      <div class="row">
        <div class="col-md-4 col-sm-6 service-container">
          <router-link to="/blog">
            <img src="../assets/images/blog-min.jpg" class="service" />
            <div class="service-card card text-center">
              <h5>Blog</h5> 
              <p>AdoraHack</p>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 col-sm-6 service-container">
          <router-link to="/videos">
            <img src="../assets/images/youtube-min.jpg" class="service" />
            <div class="service-card card text-center">
              <h5>Videos</h5> 
              <p>AdoraHack on YouTube</p>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 col-sm-6 service-container">
          <router-link to="/portfolio">
            <img src="../assets/images/portfolio.jpg" class="service" />
            <div class="service-card card text-center">
              <h5>Portfolio</h5> 
              <p>Some cool stuff I built</p>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 col-sm-6 service-container">
          <router-link to="/talks">
            <img src="../assets/images/osca.jpg" class="service" />
            <div class="service-card card text-center">
              <h5>Talks</h5> 
              <p>I speak too</p>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 col-sm-6 service-container">
          <router-link to="/community">
            <img src="../assets/images/community.jpg" class="service" />
            <div class="service-card card text-center">
              <h5>Community</h5> 
              <p>Communities I help run</p>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 col-sm-6 service-container">
          <router-link to="/podcasts">
            <img src="../assets/images/podcast-min.jpg" class="service" />
            <div class="service-card card text-center">
              <h5>Podcasts</h5> 
              <p>Podcasts I've been on</p>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 col-sm-6 service-container">
          <router-link to="/awards">
            <img src="../assets/images/awards-min.jpg" class="service" />
            <div class="service-card card text-center">
              <h5>Awards</h5> 
              <p>Recognition for my work</p>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 col-sm-6 service-container">
          <router-link to="/features">
            <img src="../assets/images/news-min.jpg" class="service" />
            <div class="service-card card text-center">
              <h5>Features</h5> 
              <p>Stories in the media</p>
            </div>
          </router-link>
        </div>
        <div class="col-md-4 col-sm-6 service-container">
          <router-link to="/books-and-courses">
            <img src="../assets/images/books-and-courses.jpg" class="service" />
            <div class="service-card card text-center">
              <h5>Books & Courses</h5> 
              <p>Learn with me!</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <FooterView />
  </div>
</template>

<script>
import SidebarView from '@/components/SidebarView.vue' 
import FooterView from '@/components/FooterView.vue' 

export default {
  components: {
    SidebarView,
    FooterView
  }
}
</script>

<style scoped>
/* NEW STYLES */
.about h1.hello{
  line-height: 98%;
}
.about h5, .about p{
  color: black;
}
.about a:hover h5, .about a:hover p{
  color: #c12688;
}
.about .profileimage{
  width: 100%;
  margin-bottom: 40px;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  border: 4px dashed #c12688;
}
.about .centerpattern{
  margin-top: 60px;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 270px;
}
.about .linkicon{
  height: 50px;
  margin: 0 auto;
  display: block;
}
.about .card{
  border-radius: 0px;
  border-color: transparent;
  -webkit-box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.7);
  -moz-box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.7);
  box-shadow: 0px 0px 20px -13px rgba(0,0,0,0.7);
  background: white;
}
.about .service{
  width: 100%;
}
.about .service-card{
  padding: 10px 30px;
  width: 70%;
  background: white;
  position: relative;
  bottom: 30px;
  left: 15%;
}
.about .service-container{
  margin-bottom: 110px;
}
.about .service-container a{
  text-decoration: none !important;
}
.about .description p{
  font-size: 14px !important;
}
@media only screen and (max-width: 1180px) {
  .about .service-container p{
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 930px) {
  .about .service-container p{
    font-size: 9px !important;
  }
}
@media only screen and (max-width: 750px) {
  .about .service-container p{
    font-size: 12px !important;
  }
}
.about .service-container p{
  margin-bottom: 0px;
}
.about h5{
  font-size: 15px;
}
.about p a{
    text-decoration: underline;
    color: black;
}
.about p a:hover{
    color: #c12688;
}
</style>

