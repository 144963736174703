<template>
    <div class="sidebar">
        <i class="fas fa-bars" @click="navShow = !navShow"></i><span class="page-title-1" v-if="previous"><router-link class="black" :to="previousLink">{{previous}}</router-link> / </span><span class="page-title-2 underline" v-if="title && previous">{{title}}</span><span class="page-title underline" v-if="title && single" >{{title}}</span>
        <transition name="fade">
          <div class="navigation" v-if="navShow">
              <div class="row">
                  <div class="col-6">
                  <i class="fas fa-times" @click="navShow = !navShow"></i>
                  </div>
                  <div class="col-6 text-right">
                    <router-link to="/">
                        <img src="../assets/images/icon.jpg" class="adora-nav-illustration" @click="navShow = !navShow" />
                    </router-link>
                  </div>
              </div>
              <br />
              <div class="row">
                  <div class="col-md-4 adoranavbg">
                  </div>
                  <div class="col-md-2"></div>
                  <div class="col-md-6">
                    <ul class="nav-items">
                        <li @click="navShow = !navShow">
                        <router-link to="/about">About</router-link>
                        </li>
                        <li @click="navShow = !navShow">
                        <router-link to="/awards">Awards</router-link>
                        </li>
                        <li @click="navShow = !navShow">
                        <router-link to="/blog">Blog</router-link>
                        </li>
                        <li @click="navShow = !navShow">
                        <router-link to="/books-and-courses">Books and Courses</router-link>
                        </li>
                        <li @click="navShow = !navShow">
                        <router-link to="/community">Community</router-link>
                        </li>
                        <li @click="navShow = !navShow">
                        <router-link to="/features">Features</router-link>
                        </li>
                        <li @click="navShow = !navShow">
                        <router-link to="/podcasts">Podcasts</router-link>
                        </li>
                        <li @click="navShow = !navShow">
                        <router-link to="/portfolio">Portfolio</router-link>
                        </li>
                        <li @click="navShow = !navShow">
                        <router-link to="/talks">Talks</router-link>
                        </li>
                        <li @click="navShow = !navShow">
                        <router-link to="/videos">Videos</router-link>
                        </li>
                        <li @click="navShow = !navShow">
                        <a href="https://www.buymeacoffee.com/adorahack" target="_blank" rel="noopener" style="color: #c12688;">Buy Me A Coffee!</a>
                        </li>
                        <li @click="navShow = !navShow">
                        <a href="https://adorahack.selar.co/cloud-engineering-book" target="_blank" rel="noopener" style="color: #c12688;">Get "Cloud Engineering for Beginners"</a>
                        </li>
                    </ul>
                  </div>
              </div>
              <div class="sidebar-bottom">
                <hr />
                <ul class="icons-list">
                    <li>
                      <a rel="noopener" target="_blank" href="https://adorahack.com"><img src="../assets/images/adorahackblack.png" class="adorahack-icon"/></a>
                    </li>
                    <li>
                      <a rel="noopener" target="_blank" href="https://twitter.com/adoranwodo"><i class="fab fa-twitter"></i></a>
                    </li>
                    <li>
                      <a rel="noopener" target="_blank" href="https://instagram.com/adorahack"><i class="fab fa-instagram"></i></a>
                    </li>
                    <li>
                      <a rel="noopener" target="_blank" href="https://www.youtube.com/channel/UCx6-_hJhiovChdaV2S7B4Ug"><i class="fab fa-youtube"></i></a>
                    </li>
                    <li>
                      <a rel="noopener" target="_blank" href="https://medium.com/@adoranwodo"><i class="fab fa-medium-m"></i></a>
                    </li>
                    <li>
                      <a rel="noopener" target="_blank" href="https://github.com/adoranwodo"><i class="fab fa-github"></i></a>
                    </li>
                    <li>
                      <a rel="noopener" target="_blank" href="https://www.linkedin.com/in/adoranwodo/"><i class="fab fa-linkedin-in"></i></a>
                    </li>
                    <li>
                      <a rel="noopener" target="_blank" href="https://en.wikipedia.org/wiki/Adora_Nwodo"><i class="fab fa-wikipedia-w"></i></a>
                    </li>
                    <li>
                      <a rel="noopener" target="_blank" href="mailto:nennenwodo@gmail.com"><i class="far fa-envelope"></i></a>
                    </li>
                    <li>
                      <a rel="noopener" target="_blank" href="https://adorahack.hashnode.dev/"><img src="../assets/images/hashnode.png" class="hashnode-icon"/></a>
                    </li>
                </ul>
              </div>
          </div>
        </transition>
    </div>
</template>

<script>
export default {
  data() {
      return {
        loading: true,
        navShow: false
      }
  },
  props: {
    title: String,
    previous: String,
    previousLink: String,
    single: String
  },
  methods: {
    toggleNav: function(){
      if(this.navShow == true){
        this.navShow = false
      }else{ this.navShow = true }
    }
  }
}
</script>
<style>
@media only screen and (max-width: 770px) {
    .navigation{
        padding: 20px !important;
    }
    .navigation .icons-list{
        margin-top: 20px !important;
    }
    .navigation .adora-nav-illustration{
        width: 50px !important;
    }
    .sidebar-bottom{
      bottom: 20px !important;
      left: 20px !important;
      right: 20px !important;
    }
}
.navigation{
  padding: 50px;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgb(255,255,255) !important;
  z-index: 3000;
  top: 0;
  left: 0;
  right: 0;
  -webkit-box-shadow: 0px 0px 20px -13px rgba(0,0,0,1);
  -moz-box-shadow: 0px 0px 20px -13px rgba(0,0,0,1);
  box-shadow: 0px 0px 20px -13px rgba(0,0,0,1);
}
.sidebar-bottom{
  position: absolute;
  bottom: 50px;
  left: 50px;
  right: 50px
}
.text-right{
  text-align: right !important;
}
.navigation .adora-nav-illustration{
    width: 60px;
    border-radius: 50px;
    border: 1px dashed #c12688;
}
.navigation ul.nav-items{
  margin-left: -40px;
  text-align: right;
}
.navigation ul.nav-items li{
  list-style-type: none;
}
.navigation ul.nav-items li a{
  color: black;
  margin-top: 15px;
  display: block;
  font-size: 1.0rem;
}
.navigation ul.nav-items li a:hover{
  color: #c12688;
}
.navigation .adoranavbg{
  background: url('../assets/images/io.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: .2;
}
.navigation .adorahack-icon{
  width: 17px;
  margin-bottom: 4px;
}
.navigation .hashnode-icon{
  width: 17px;
  margin-bottom: 4px;
}
.navigation .icons-list{
  margin-top: 40px;
  margin-bottom: 0px;
  margin-left: -40px;
}
.navigation .icons-list li{
  list-style-type: none;
  display: inline;
  margin-right: 10px;
}
.navigation .icons-list li img{
  margin-top: -5px;
}
.navigation .icons-list li a{
  /*font-size: 22px;*/
  color: black !important;
}
.navigation .icons-list i{
  font-size: 20px;
}
.underline{
  text-decoration: underline;
}
.sidebar .page-title{
    text-decoration: underline;
    margin-left: 20px;
}
.sidebar .page-title-1{
    margin-left: 20px;
}
.sidebar .page-title-2{
    margin-left: 0px;
    font-weight: 400 !important;
}
.sidebar .black{
  color: black !important;
}
.sidebar .fas.fa-bars, .sidebar .fas.fa-times{
  cursor: pointer;
}
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.fade-enter-active {
  animation: fadeIn 1s;
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
</style>

